import React, { useContext, useEffect, useState, useRef } from "react";
import { Card } from "react-bootstrap";
import { Context } from "../index";
import { observer } from "mobx-react-lite";
import Calendar from "./Calendar";
import { useNavigate } from "react-router-dom";
import { POLICIES_STRATEGIES_ROUTE } from "../utils/consts";
import { fetchPoliciesStrategies } from "../http/resourcesServicesAPI";
import { Spinner } from "react-bootstrap";
import ShareButton from "./UI/ShareButton";

const PoliciesStrategies = observer(() => {
	const navigate = useNavigate();
	const { resourcesServices } = useContext(Context);
	const [loading, setLoading] = useState(true);

	const printRef = useRef(null);
	const handlePrint = () => {
		const originalBodyContent = document.body.innerHTML;
		document.body.innerHTML = printRef.current.outerHTML;
		window.print();

		onafterprint = (e) => {
			console.log(e);

			document.body.innerHTML = originalBodyContent;
			window.location.reload();
		};
	};

	useEffect(() => {
		fetchPoliciesStrategies()
			.then((data) => {
				resourcesServices.setPoliciesStrategies(data);
			})
			.finally(() => setLoading(false));
	}, [resourcesServices]);

	if (loading) {
		return (
			<div className='d-flex align-items-center justify-content-center py-5'>
				<Spinner animation={"grow"} />
			</div>
		);
	}
	return (
		<div className='d-flex flex-xl-row flex-column justify-content-center px-xl-4 px-2 border-bottom'>
			<div
				className='px-5 col-xl-9 col-12 py-4'
				ref={printRef}>
				<div className='block-title'>
					<span>Politici și strategii</span>
				</div>
				<div className='d-flex flex-row flex-wrap align-items-center justify-content-around test-print'>
					{resourcesServices.policiesStrategies.count > 0
						? resourcesServices.policiesStrategies.rows.map((item, idx) => {
								return (
									<Card
										key={idx}
										style={{ width: "22rem" }}
										className='my-3'
										onClick={() => navigate(POLICIES_STRATEGIES_ROUTE + "/" + item.id)}>
										{item.img ? (
											<Card.Img
												variant='top'
												src={process.env.REACT_APP_API_URL + item.img}
											/>
										) : null}
										<Card.Body>
											<Card.Title className='mb-3 d-flex align-items-center justify-content-between'>
												<span
													className='border border-primary rounded text-primary p-2'
													style={{ fontSize: "1rem" }}>
													{item.date}
												</span>
											</Card.Title>
											<Card.Text
												role='button'
												className='truncate'
												onClick={() => navigate(POLICIES_STRATEGIES_ROUTE + "/" + item.id)}>
												{item.title}
											</Card.Text>
										</Card.Body>
									</Card>
								);
						  })
						: null}
				</div>

				<div className='map d-flex flex-column py-4'>
					<div className='block-title'>
						<span>Urmăriți-ne pe</span>
					</div>
					<div className='d-flex flex-xl-row flex-column justify-content-between'>
						<div className='d-flex flex-xl-row flex-column justify-content-around py-3'>
							<a
								href='#'
								className='d-flex flex-row align-items-center justify-content-between py-2 px-3 text-decoration-none '>
								<i className='bi bi-youtube pe-3 icon-3 text-primary' />
								<span className='text-primary font-2'>YOUTUBE</span>
							</a>
							<a
								href='#'
								className='d-flex flex-row align-items-center justify-content-between py-2 px-3 text-decoration-none '>
								<i className='bi bi-facebook pe-3 icon-3 text-primary' />
								<span className='text-primary font-2'>FACEBOOK</span>
							</a>
							<a
								href='#'
								className='d-flex flex-row align-items-center justify-content-between py-2 px-3 text-decoration-none '>
								<i className='bi bi-telegram pe-3 icon-3 text-primary' />
								<span className='text-primary font-2'>TELEGRAM</span>
							</a>
						</div>
						<div className='d-flex flex-row align-items-center justify-content-end ps-1 pe-5'>
							<i
								role='button'
								onClick={() => handlePrint()}
								className='bi bi-printer text-primary icon-3'
								style={{
									fontWeight: "600 !important",
									WebkitTextStroke: 1,
								}}
							/>
							<ShareButton />
						</div>
					</div>
				</div>
			</div>
			<Calendar />
		</div>
	);
});

export default PoliciesStrategies;
