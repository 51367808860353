import React, { useState } from "react";
import { Form, Modal, Button, Image } from "react-bootstrap";
import { createLastNews } from "../../../http/NewsAPI";
import { createContacts } from "../../../http/navLinksAPI";

const AddContactsModal = ({ show, onHide }) => {
	const [title, setTitle] = useState("");
	const [location, setLocation] = useState("");
	const [locationLink, setLocationLink] = useState("");
	const [responsible, setResponsible] = useState("");
	const [days, setDays] = useState("");
	const [time, setTime] = useState("");
	const [email, setEmail] = useState([]);
	const [phone, setPhone] = useState([]);

	const addEmail = () => {
		setEmail([...email, { email: "", number: Date.now() }]);
	};
	const removeEmail = (number) => {
		setEmail(email.filter((i) => i.number !== number));
	};

	const changeEmail = (key, value, number) => {
		setEmail(email.map((i) => (i.number === number ? { ...i, [key]: value } : i)));
	};

	const addPhone = () => {
		setPhone([...phone, { phone: "", number: Date.now() }]);
	};
	const removePhone = (number) => {
		setPhone(phone.filter((i) => i.number !== number));
	};

	const changePhone = (key, value, number) => {
		setPhone(phone.map((i) => (i.number === number ? { ...i, [key]: value } : i)));
	};

	const AddContacts = () => {
		const formData = new FormData();
		formData.append("title", title);
		formData.append("location", location);
		formData.append("location_link", locationLink);
		formData.append("responsible", responsible);
		formData.append("days", days);
		formData.append("time", time);
		formData.append("email", JSON.stringify(email));
		formData.append("phone", JSON.stringify(phone));
		createContacts(formData).then(() => window.location.reload());
	};

	return (
		<Modal
			show={show}
			onHide={onHide}
			className='add-news-modal'>
			<Modal.Header
				closeButton={onHide}
				className='bg-primary'
				closeVariant='white'>
				<Modal.Title className='text-white'>Adauga un Contact</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className='d-flex flex-column justify-content-around'>
					<span className='text-primary  font-2 py-2 ps-1'>Denumirea Contactului</span>
					<Form.Control
						aria-label='Title'
						value={title}
						onChange={(e) => {
							setTitle(e.target.value);
						}}
					/>
					<span className='text-primary  font-2 py-2 ps-1'>Locatia</span>
					<Form.Control
						aria-label='Title'
						value={location}
						onChange={(e) => {
							setLocation(e.target.value);
						}}
					/>
					<span className='text-primary  font-2 py-2 ps-1'>Legătură cu harta</span>
					<Form.Control
						aria-label='Title'
						value={locationLink}
						onChange={(e) => {
							setLocationLink(e.target.value);
						}}
					/>
					<span className='text-primary  font-2 py-2 ps-1'>Persoana Responsabila</span>
					<Form.Control
						aria-label='Title'
						value={responsible}
						onChange={(e) => {
							setResponsible(e.target.value);
						}}
					/>
					<span className='text-primary  font-2 py-2 ps-1'>Program de lucru</span>
					<Form.Control
						aria-label='Title'
						value={days}
						onChange={(e) => {
							setDays(e.target.value);
						}}
					/>
					<span className='text-primary  font-2 py-2 ps-1'>Orele de Lucru</span>
					<Form.Control
						aria-label='Title'
						value={time}
						onChange={(e) => {
							setTime(e.target.value);
						}}
					/>
					<span className='text-primary  font-2 py-2 ps-1'>E-Mail</span>
					<Button
						className='btn-primary mb-4'
						onClick={addEmail}>
						Adauga E-Mail
					</Button>
					{email.map((i) => (
						<div
							className='d-flex flex-xl-row flex-column align-items-center justify-content-around'
							key={i.number}>
							<Form.Control
								className='me-xl-5'
								value={i.description}
								onChange={(e) => changeEmail("email", e.target.value, i.number)}
								placeholder={"Introduceti E-Mail-ul"}
							/>
							<Button
								variant={"outline-danger"}
								className='my-sm-4'
								onClick={() => removeEmail(i.number)}>
								Sterge
							</Button>
						</div>
					))}
					<span className='text-primary  font-2 py-2 ps-1'>Nr. de Telefon</span>
					<Button
						className='btn-primary mb-4'
						onClick={addPhone}>
						Adauga nr. de Telefon
					</Button>
					{phone.map((i) => (
						<div
							className='d-flex flex-xl-row flex-column align-items-center justify-content-around'
							key={i.number}>
							<Form.Control
								className='me-xl-5'
								value={i.description}
								onChange={(e) => changePhone("phone", e.target.value, i.number)}
								placeholder={"Introduceti nr. de telefon"}
							/>
							<Button
								variant={"outline-danger"}
								className='my-sm-4'
								onClick={() => removePhone(i.number)}>
								Sterge
							</Button>
						</div>
					))}
				</div>
			</Modal.Body>
			<Modal.Footer>
				<Button
					variant='secondary'
					onClick={onHide}>
					Close
				</Button>
				<Button
					variant='primary'
					onClick={() => {
						onHide();
						AddContacts();
					}}>
					Confirm
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default AddContactsModal;
