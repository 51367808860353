import { useContext, useEffect, useState } from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Image, Form, Button, Dropdown, Modal } from "react-bootstrap";
import "bootstrap-icons/font/bootstrap-icons.css";
import { Link, useNavigate } from "react-router-dom";
import {
	BUSINESS_ROUTE,
	CONTACTS_ROUTE,
	COUNCILLORS_LIST_ROUTE,
	DECENTRALIZED_SERVICES_ROUTE,
	DISTRICT_BUDGET_ROUTE,
	// DRAFT_DECISIONS_ROUTE,
	HISTORY_ROUTE,
	HOME_ROUTE,
	// DISTRICT_DECISIONS_ROUTE,
	MANAGEMENT_ROUTE,
	MEETINGS_ROUTE,
	MUNICIPAL_ENTERPRISES_ROUTE,
	NGOV_ORGANIZATIONS_ROUTE,
	ORGANIZATION_ROUTE,
	PRESIDENTS_MESSAGE_ROUTE,
	PUBLIC_NOTICES_ROUTE,
	REGULATIONS_ROUTE,
	SOCIAL_ECONOMIC_INDICATORS_ROUTE,
	SPECIALIZED_COMMITTEES_MEETS_ROUTE,
	SPECIALIZED_COMMITTEES_ROUTE,
	TOURISM_MARKETING_PLAN_ROUTE,
	TRANSPARENCY_ROUTE,
	VERBAL_PROCEDURE_ROUTE,
} from "../utils/consts";
import { Context } from "../index";
import { observer } from "mobx-react-lite";
import { fetchOneContactInformation } from "../http/contactInformationAPI";
import { Spinner } from "react-bootstrap";

const Header = observer(() => {
	const navigate = useNavigate();
	const [showSearch, setShowSearch] = useState(false);
	const [loading, setLoading] = useState(true);

	const searchClose = () => setShowSearch(false);
	const searchShow = () => setShowSearch(true);

	const { contactInformation } = useContext(Context);
	useEffect(() => {
		fetchOneContactInformation(1)
			.then((data) => {
				contactInformation.setContactInformation(data);
			})
			.finally(() => setLoading(false));
	}, [contactInformation]);
	if (loading) {
		return (
			<div className='d-flex align-items-center justify-content-center py-5'>
				<Spinner animation={"grow"} />
			</div>
		);
	}
	return (
		<>
			<div className='d-flex flex-column shadow'>
				{contactInformation.contactInformation ? (
					<div className='d-flex flex-xl-row flex-column justify-content-between align-items-center header_info bg-primary px-3 font-inter'>
						<div className='d-flex flex-xl-row flex-column flex-wrap align-items-center'>
							<span className='mun-info'>{contactInformation.contactInformation.page_title}</span>
							<div className='d-flex ps-xl-5'>
								<Link
									className='text-decoration-none text-white'
									to={contactInformation.contactInformation.facebook_link}>
									<i className='bi bi-facebook px-2' />
								</Link>
								<Link
									className='text-decoration-none text-white'
									to={contactInformation.contactInformation.telegram_link}>
									<i className='bi bi-telegram px-2' />
								</Link>
								<Link
									className='text-decoration-none text-white'
									to={contactInformation.contactInformation.youtube_link}>
									<i className='bi bi-youtube px-2' />
								</Link>
							</div>
						</div>
						<div className='d-flex flex-row flex-wrap align-items-center justify-content-between'>
							<div className='d-md-flex flex-row align-items-center px-3 d-none'>
								<i className='bi bi-diagram-3-fill' />
								<span className='ps-2 font-1'>Harta site-ului</span>
							</div>
							<div className='d-md-flex flex-row align-items-center px-3 d-none'>
								<i className='bi bi-envelope' />
								<span className='ps-2 font-1'>{contactInformation.contactInformation.org_email}</span>
							</div>
							<div className='d-md-flex flex-row align-items-center px-3 d-none'>
								<i className='bi bi-telephone' />
								<span className='ps-2 font-1'>{contactInformation.contactInformation.org_phone}</span>
							</div>
							<Dropdown className='lang'>
								<Dropdown.Toggle
									id='dropdown-basic'
									className='font-1'>
									Limba
								</Dropdown.Toggle>

								<Dropdown.Menu>
									<Dropdown.Item
										className='rounded'
										active>
										RO
									</Dropdown.Item>
									{/* <Dropdown.Item href="#/action-2">RU</Dropdown.Item>
                            <Dropdown.Item href="#/action-3">EN</Dropdown.Item> */}
								</Dropdown.Menu>
							</Dropdown>
						</div>
					</div>
				) : null}

				<Navbar
					collapseOnSelect
					expand='lg'
					className='bg-white px-4 color-dark'>
					{contactInformation.contactInformation ? (
						<Navbar.Brand
							className='pe-auto'
							role='button'>
							<Link
								className='text-decoration-none text-primary'
								to={HOME_ROUTE}>
								<div className='d-flex flex-row user-select-none'>
									{contactInformation.contactInformation.org_logo ? (
										<Image
											className='d-none d-sm-flex'
											style={{ height: 80, width: "auto" }}
											src={process.env.REACT_APP_API_URL + contactInformation.contactInformation.org_logo}
										/>
									) : null}
									<div className='d-flex flex-column align-items-center justify-content-center ps-3'>
										<span className='org-name text-center text-wrap'>
											{contactInformation.contactInformation.org_name}
										</span>
										<span className='org-region'>{contactInformation.contactInformation.org_region}</span>
									</div>
								</div>
							</Link>
						</Navbar.Brand>
					) : null}
					<Navbar.Toggle aria-controls='responsive-navbar-nav' />
					<Navbar.Collapse id='responsive-navbar-nav'>
						<Nav className='ms-auto align-items-center text-primary'>
							<Nav.Link href='/'>Acasă</Nav.Link>
							<NavDropdown
								title='Comunitate'
								id='collapsible-nav-dropdown'>
								<NavDropdown.Item href='https://drive.google.com/file/d/1Mlq_o-jOy_TbOECvixrAPGtc0E-AaNoL/view?usp=sharing'>
									Pașaportul raionului
								</NavDropdown.Item>
								<NavDropdown.Item href={PRESIDENTS_MESSAGE_ROUTE}>Mesajul Presedintelui</NavDropdown.Item>
								<NavDropdown.Item href={HISTORY_ROUTE}>Istorie</NavDropdown.Item>
								<NavDropdown.Item href={DECENTRALIZED_SERVICES_ROUTE}>Serviciile descentralizate</NavDropdown.Item>
								<NavDropdown.Item href={MUNICIPAL_ENTERPRISES_ROUTE}>Întreprinderi municipale</NavDropdown.Item>
								<NavDropdown.Item href={NGOV_ORGANIZATIONS_ROUTE}>Organizații neguvernamentale</NavDropdown.Item>
								<NavDropdown.Item href={BUSINESS_ROUTE}>Afaceri</NavDropdown.Item>
							</NavDropdown>
							<NavDropdown
								title='Administrație'
								id='collapsible-nav-dropdown'>
								<NavDropdown.Item href={MANAGEMENT_ROUTE}>Conducerea</NavDropdown.Item>
								<NavDropdown.Item href='https://drive.google.com/file/d/1Nu_cha8M1mFTGmJiSHeVCCBrTumncQK1/view'>
									Aparatul Preşedintelui
								</NavDropdown.Item>
								<NavDropdown.Item href={COUNCILLORS_LIST_ROUTE}>Consilieri</NavDropdown.Item>
								<NavDropdown.Item href={ORGANIZATION_ROUTE}>Organigrama</NavDropdown.Item>
								<NavDropdown.Item href={SPECIALIZED_COMMITTEES_ROUTE}>Comisiile</NavDropdown.Item>
								<NavDropdown.Item href={REGULATIONS_ROUTE}>Regulamente</NavDropdown.Item>
								<NavDropdown.Item href={MEETINGS_ROUTE}>Sedintele</NavDropdown.Item>
								<NavDropdown.Item href={VERBAL_PROCEDURE_ROUTE}>Procese verbale</NavDropdown.Item>
								<NavDropdown.Item href={SPECIALIZED_COMMITTEES_MEETS_ROUTE}>
									Sedinte Comisii de Specialitate
								</NavDropdown.Item>
								{/* <NavDropdown.Item href={DRAFT_DECISIONS_ROUTE}>
									Proiecte de Decizii
								</NavDropdown.Item>
								<NavDropdown.Item href={DISTRICT_DECISIONS_ROUTE}>
									Decizii/Acte Locale
								</NavDropdown.Item> */}
							</NavDropdown>
							<NavDropdown
								title='Economie'
								id='collapsible-nav-dropdown'>
								<NavDropdown.Item href={SOCIAL_ECONOMIC_INDICATORS_ROUTE}>Indicatorii Social-Economici</NavDropdown.Item>
								<NavDropdown.Item href={DISTRICT_BUDGET_ROUTE}>Bugetul Raional</NavDropdown.Item>
								<NavDropdown.Item href='https://drive.google.com/file/d/1g26PEtDTFUDNpMM4lXPaByZDnZ6L_7o5/view'>
									Proprietate Publică
								</NavDropdown.Item>
								<NavDropdown.Item href={TOURISM_MARKETING_PLAN_ROUTE}>Planul de Marketing in Turism</NavDropdown.Item>
							</NavDropdown>
							<Nav.Link href={TRANSPARENCY_ROUTE}>Transparență</Nav.Link>
							<Nav.Link href={PUBLIC_NOTICES_ROUTE}>Anunțuri de interes public</Nav.Link>
							<Nav.Link href={CONTACTS_ROUTE}>Contacte</Nav.Link>
							<div className='ps-xl-2'>
								<Button
									variant='outline-primary search-btn'
									onClick={searchShow}
									className='col-12'>
									<i className='bi bi-search text-primary' />
								</Button>
								<Modal
									show={showSearch}
									onHide={searchClose}>
									<Modal.Header closeButton>
										<Modal.Title className='text-primary'>Search</Modal.Title>
									</Modal.Header>
									<Modal.Body>
										<div className='d-flex flex-column align-items-center justify-content-center'>
											<Form.Control
												type='search'
												placeholder='Search'
												aria-label='Search'
											/>
										</div>
									</Modal.Body>
									<Modal.Footer>
										<Button
											variant='outline-primary'
											onClick={searchClose}>
											Cancel
										</Button>
									</Modal.Footer>
								</Modal>
							</div>
						</Nav>
					</Navbar.Collapse>
				</Navbar>
			</div>
		</>
	);
});

export default Header;
