import Admin from "./components/Admin/Admin";
import Home from "./pages/Home";
import Biography from "./pages/Biography";
import Contacts from "./components/Contacts";
import Auth from "./pages/Auth";
import {
	ADMIN_ROUTE,
	LOGIN_ROUTE,
	HOME_ROUTE,
	LAST_NEWS_ROUTE,
	CITY_NEWS_ROUTE,
	BIOGRAPHY_ROUTE,
	PUBLIC_SERVICE_ROUTE,
	CONTACTS_ROUTE,
	EVENTS_ROUTE,
	PUBLIC_INFORMATION_ROUTE,
	DECISION_PROCESS_ROUTE,
	PUBLIC_NOTICES_ROUTE,
	DECENTRALIZED_SERVICES_ROUTE,
	MUNICIPAL_ENTERPRISES_ROUTE,
	NGOV_ORGANIZATIONS_ROUTE,
	ORGANIZATION_ROUTE,
	BUSINESS_ROUTE,
	HISTORY_ROUTE,
	PRESIDENTS_MESSAGE_ROUTE,
	COUNCILLORS_LIST_ROUTE,
	SPECIALIZED_COMMITTEES_ROUTE,
	REGULATIONS_ROUTE,
	MEETINGS_ROUTE,
	VERBAL_PROCEDURE_ROUTE,
	DRAFT_DECISIONS_ROUTE,
	LOCAL_DECISIONS_ACTS_ROUTE,
	SPECIALIZED_COMMITTEES_MEETS_ROUTE,
	SOCIAL_ECONOMIC_INDICATORS_ROUTE,
	DISTRICT_BUDGET_ROUTE,
	TOURISM_MARKETING_PLAN_ROUTE,
	MANAGEMENT_ROUTE,
	TRANSPARENCY_ROUTE,
	DISTRICT_DECISIONS_ROUTE,
	DISTRICT_PROVISIONS_ROUTE,
	APPLICATION_FORMS_ROUTE,
	INITIATING_PUBLIC_PRIVATE_PARTNERSHIPS_ROUTE,
	POLICIES_STRATEGIES_ROUTE,
	PUBLIC_TRANSPORT_NETWORK_ROUTE,
	PUBLIC_CONSULTATIONS_ROUTE,
	LOCATION_IN_NUMBERSS_ROUTE,
	SUCCESS_STORIES_ROUTE,
	LOCATION_DEVELOPMENT_STRATEGY_ROUTE,
	KEY_SECTORS_ROUTE,
	PARTNER_SUPPORTED_PROJECTS_ROUTE,
	STARTING_BUSINESS_ROUTE,
	TOURIST_ROUTES_ROUTE,
	HOTELS_ROUTE,
	MUSEUMS_ROUTE,
	PARKS_ROUTE,
	TOURIST_ACTIVITIES_ROUTE,
	HISTORIC_BUILDINGS_ROUTE,
	MONUMENTS_ROUTE,
	NOTABLE_PERSONALITIES_ROUTE,
	FOR_KIDS_ROUTE,
	CHURCHES_MONASTERIES_ROUTE,
	LOWER_HIERARCHICAL_ORGANS_ROUTE,
	REPORTS_ROUTE,
	INFORMATION_ROUTE,
	DECISIONS_ROUTE,
	CECE_VERBAL_PROCEDURE_ROUTE,
	ANNOUNCEMENTS_ROUTE,
	PHOTO_GALLERY_ROUTE,
	ONLINE_MEETING_ROUTE,
	EVENT_PAGE_ROUTE,
	EUROPEAN_PROJECTS_ROUTE,
	CITIZENS_HEARING_PROGRAM_ROUTE,
	ACQUISITION_ROUTE,
	INITIATION_ROUTE,
	RECOMENDATIONS_ROUTE,
	VACANCIES_ROUTE,
	TENDERS_ROUTE,
	COMMITTEE_MEETINGS_ROUTE,
	OTHER_ROUTE,
	HEAD_ONLINE_ROUTE,
	MAIN_BANNER_INFO,
	EXTRAORDINARY_COMMISSIONS_INFO,
	EXTRAORDINARY_COMMISSIONS_ROUTE,
	LD_GENERAL_ROUTE,
	LD_LOCAL_PUBLIC_ADMINISTRATION_ROUTE,
	LD_BUDGET_FINANCE_ROUTE,
	LD_COMMERCE_ROUTE,
	LD_LOCAL_TAXES_ROUTE,
	LD_CONSTRUCTION_ROUTE,
	LD_EMPLOYMENT_RELATIONSHIPS_ROUTE,
	LD_TAND_TENURE_ROUTE,
	LD_ORGANIZING_TENDERS_ROUTE,
	LD_MANAGING_PUBLIC_HERITAGE_ROUTE,
	LD_OTHER_DOMAINS_ROUTE,
} from "./utils/consts";
import CityNewsList from "./pages/CityNewsList";
import LastNewsList from "./pages/LastNewsList";
import PublicInfo from "./pages/PublicInfo";
import DecisionProcess from "./pages/DecisionProcess";
import PublicNotices from "./pages/PublicNotices";
import DecentralizedServices from "./pages/DecentralizedServices";
import MunicipalEnterprises from "./pages/MunicipalEnterprises";
import NGovOrganizations from "./pages/NGovOrganizations";
import Business from "./pages/Business";
import History from "./pages/History";
import PresidentsMessage from "./pages/PresidentsMessage";
import CouncillorsList from "./pages/CouncillorsList";
import SpecializedCommittees from "./pages/SpecializedCommittees";
import Regulations from "./pages/Regulations";
import Meetings from "./pages/Meetings";
import VerbalProcedure from "./pages/VerbalProcedure";
import DraftDecisions from "./pages/DraftDecisions";
import LocalDecisionsActs from "./pages/LocalDecisionsActs";
import SpecialCommitteesMeets from "./pages/SpecialCommitteesMeets";
import SocialEconomicIndicators from "./pages/SocialEconomicIndicators";
import DistrictBudget from "./pages/DistrictBudget";
import TourismMarketingPlan from "./pages/TourismMarketingPlan";
import Management from "./pages/Management";
import InformationProcess from "./components/InformationProcess";
import DistrictDecisions from "./pages/DistrictDecisions";
import DistrictProvisions from "./pages/DistrictProvisions";
import ApplicationForms from "./pages/ApplicationForms";
import InitiatingPublicPrivatePartnerships from "./pages/InitiatingPublicPrivatePartnerships";
import PoliciesStrategies from "./components/PoliciesStrategies";
import PublicTransportNetwork from "./pages/PublicTransportNetwork";
import PublicConsultations from "./pages/PublicConsultations";
import LocationInNumbers from "./pages/LocationInNumbers";
import SuccessStories from "./pages/SuccessStories";
import LocationDevelopmentStrategy from "./pages/LocationDevelopmentStrategy";
import KeySectors from "./pages/KeySectors";
import PartnerSupportedProjects from "./pages/PartnerSupportedProjects";
import StartingBusiness from "./pages/StartingBusiness";
import TouristRoutes from "./pages/TouristRoutes";
import Museums from "./pages/Museums";
import Parks from "./pages/Parks";
import TouristActivities from "./pages/TouristActivities";
import HistoricBuildings from "./pages/HistoricBuildings";
import Monuments from "./pages/Monuments";
import NotablePersonalities from "./pages/NotablePersonalities";
import ForKids from "./pages/ForKids";
import ChurchesMonasteries from "./pages/ChurchesMonasteries";
import Hotels from "./pages/Hotels";
import LowerHierarchicalOrgans from "./pages/LowerHierarchicalOrgans";
import Reports from "./pages/Reports";
import Information from "./pages/Information";
import Decisions from "./pages/Decizions";
import CECEVerbalProcedure from "./pages/CECEVerbalProcedure";
import Announcements from "./pages/Announcements";
import OnlineMeeting from "./pages/OnlineMeeting";
import EventPage from "./pages/EventPage";
import EventList from "./components/EventList";
import EuropeanProjects from "./pages/EuropeanProjects";
import CitizensHearingProgram from "./pages/CitizensHearingProgram";
import Organization from "./pages/Organization";
import AcquisitionPage from "./pages/DocumentPages/AcquisitionPage";
import ApplicationFormsPage from "./pages/DocumentPages/ApplicationFormsPage";
import CECEAnnouncementsPage from "./pages/DocumentPages/CECEAnnouncementsPage";
import CECEDecisionsPage from "./pages/DocumentPages/CECEDecisionsPage";
import CECEInformationPage from "./pages/DocumentPages/CECEInformationPage";
import CECEVerbalProcedurePage from "./pages/DocumentPages/CECEVerbalProcedurePage";
import CityNewsPage from "./pages/DocumentPages/CityNewsPage";
import ConsultationPage from "./pages/DocumentPages/ConsultationPage";
import DistrictBudgetPage from "./pages/DocumentPages/DistrictBudgetPage";
import DraftDecisionsPage from "./pages/DocumentPages/DraftDecisionsPage";
import InitiationPage from "./pages/DocumentPages/InitiationPage";
import MeetingsPage from "./pages/DocumentPages/MeetingsPage";
import PhotoGalleryPage from "./pages/DocumentPages/PhotoGalleryPage";
import PoliciesStrategiesPage from "./pages/DocumentPages/PoliciesStrategiesPage";
import ProjectPage from "./pages/DocumentPages/ProjectPage";
import PublicNoticesPage from "./pages/DocumentPages/PublicNoticesPage";
import PublicTransportNetworkPage from "./pages/DocumentPages/PublicTransportNetworkPage";
import RecommendationsPage from "./pages/DocumentPages/RecommendationsPage";
import RegulationsPage from "./pages/DocumentPages/RegulationsPage";
import ReportsPage from "./pages/DocumentPages/ReportsPage";
import SpecialCommitteesMeetsPage from "./pages/DocumentPages/SpecialCommitteesMeetsPage";
import TendersPage from "./pages/DocumentPages/TendersPage";
import TourismMarketingPlanPage from "./pages/DocumentPages/TourismMarketingPlanPage";
import VacanciesPage from "./pages/DocumentPages/VacanciesPage";
import VerbalProcedurePage from "./pages/DocumentPages/VerbalProcedurePage";
import LastNewsPage from "./pages/DocumentPages/LastNewsPage";
import CommitteeMeetingsPage from "./pages/DocumentPages/CommitteeMeetingsPage";
import OnlineMeetingsPage from "./pages/DocumentPages/OnlineMeetingsPage";
import OtherPage from "./pages/DocumentPages/OtherPage";
import HeadOnlinePage from "./pages/DocumentPages/HeadOnlinePage";
import MainBannerInfoPage from "./pages/DocumentPages/MainBannerInfoPage";
import ExtraordinaryCommissionsPage from "./pages/DocumentPages/ExtraordinaryCommissionsPage";
import LDGeneral from "./pages/LDGeneral";
import LDLocalPublicAdministration from "./pages/LDLocalPublicAdministration";
import LDBudgetFinance from "./pages/LDBudgetFinance";
import LDCommerce from "./pages/LDCommerce";
import LDLocalTaxes from "./pages/LDLocalTaxes";
import LDConstruction from "./pages/LDConstruction";
import LDEmploymentRelationships from "./pages/LDEmploymentRelationships";
import LDTandTenure from "./pages/LDTandTenure";
import LDOrganizingTenders from "./pages/LDOrganizingTenders";
import LMDanagingPublicHeritage from "./pages/LMDanagingPublicHeritage";
import LDOtherDomains from "./pages/LDOtherDomains";
import ExtraordinaryCommittees from "./pages/ExtraordinaryCommittees";

export const authRoutes = [
	{
		path: ADMIN_ROUTE,
		Component: <Admin />,
	},
];

export const publicRoutes = [
	{
		path: LOGIN_ROUTE,
		Component: <Auth />,
	},
	{
		path: HOME_ROUTE,
		Component: <Home />,
	},
	{
		path: BIOGRAPHY_ROUTE,
		Component: <Biography />,
	},
	{
		path: BIOGRAPHY_ROUTE + "/:id",
		Component: <Biography />,
	},
	{
		path: PUBLIC_SERVICE_ROUTE,
		Component: <Home />,
	},
	{
		path: CITY_NEWS_ROUTE,
		Component: <CityNewsList />,
	},
	{
		path: CITY_NEWS_ROUTE + "/:id",
		Component: <CityNewsPage />,
	},
	{
		path: LAST_NEWS_ROUTE,
		Component: <LastNewsList />,
	},
	{
		path: LAST_NEWS_ROUTE + "/:id",
		Component: <LastNewsPage />,
	},
	{
		path: CONTACTS_ROUTE,
		Component: <Contacts />,
	},
	{
		path: EVENTS_ROUTE,
		Component: <EventList />,
	},
	{
		path: PUBLIC_INFORMATION_ROUTE,
		Component: <PublicInfo />,
	},
	{
		path: DECISION_PROCESS_ROUTE,
		Component: <DecisionProcess />,
	},
	{
		path: PUBLIC_NOTICES_ROUTE,
		Component: <PublicNotices />,
	},
	{
		path: PUBLIC_NOTICES_ROUTE + "/:id",
		Component: <PublicNoticesPage />,
	},
	{
		path: DECENTRALIZED_SERVICES_ROUTE,
		Component: <DecentralizedServices />,
	},
	{
		path: MUNICIPAL_ENTERPRISES_ROUTE,
		Component: <MunicipalEnterprises />,
	},
	{
		path: NGOV_ORGANIZATIONS_ROUTE,
		Component: <NGovOrganizations />,
	},
	{
		path: BUSINESS_ROUTE,
		Component: <Business />,
	},
	{
		path: HISTORY_ROUTE,
		Component: <History />,
	},
	{
		path: PRESIDENTS_MESSAGE_ROUTE,
		Component: <PresidentsMessage />,
	},
	{
		path: COUNCILLORS_LIST_ROUTE,
		Component: <CouncillorsList />,
	},
	{
		path: SPECIALIZED_COMMITTEES_ROUTE,
		Component: <SpecializedCommittees />,
	},
	{
		path: REGULATIONS_ROUTE,
		Component: <Regulations />,
	},
	{
		path: REGULATIONS_ROUTE + "/:id",
		Component: <RegulationsPage />,
	},
	{
		path: MEETINGS_ROUTE,
		Component: <Meetings />,
	},
	{
		path: MEETINGS_ROUTE + "/:id",
		Component: <MeetingsPage />,
	},
	{
		path: VERBAL_PROCEDURE_ROUTE,
		Component: <VerbalProcedure />,
	},
	{
		path: VERBAL_PROCEDURE_ROUTE + "/:id",
		Component: <VerbalProcedurePage />,
	},
	{
		path: DRAFT_DECISIONS_ROUTE,
		Component: <DraftDecisions />,
	},
	{
		path: DRAFT_DECISIONS_ROUTE + "/:id",
		Component: <DraftDecisionsPage />,
	},
	{
		path: LOCAL_DECISIONS_ACTS_ROUTE,
		Component: <LocalDecisionsActs />,
	},
	{
		path: SPECIALIZED_COMMITTEES_MEETS_ROUTE,
		Component: <SpecialCommitteesMeets />,
	},
	{
		path: SPECIALIZED_COMMITTEES_MEETS_ROUTE + "/:id",
		Component: <SpecialCommitteesMeetsPage />,
	},
	{
		path: SOCIAL_ECONOMIC_INDICATORS_ROUTE,
		Component: <SocialEconomicIndicators />,
	},
	{
		path: TENDERS_ROUTE + "/:id",
		Component: <TendersPage />,
	},
	{
		path: DISTRICT_BUDGET_ROUTE,
		Component: <DistrictBudget />,
	},
	{
		path: DISTRICT_BUDGET_ROUTE + "/:id",
		Component: <DistrictBudgetPage />,
	},
	{
		path: TOURISM_MARKETING_PLAN_ROUTE,
		Component: <TourismMarketingPlan />,
	},
	{
		path: TOURISM_MARKETING_PLAN_ROUTE + "/:id",
		Component: <TourismMarketingPlanPage />,
	},
	{
		path: MANAGEMENT_ROUTE,
		Component: <Management />,
	},
	{
		path: TRANSPARENCY_ROUTE,
		Component: <InformationProcess />,
	},
	{
		path: DISTRICT_DECISIONS_ROUTE,
		Component: <DistrictDecisions />,
	},
	{
		path: DISTRICT_PROVISIONS_ROUTE,
		Component: <DistrictProvisions />,
	},
	{
		path: APPLICATION_FORMS_ROUTE,
		Component: <ApplicationForms />,
	},
	{
		path: APPLICATION_FORMS_ROUTE + "/:id",
		Component: <ApplicationFormsPage />,
	},
	{
		path: INITIATING_PUBLIC_PRIVATE_PARTNERSHIPS_ROUTE,
		Component: <InitiatingPublicPrivatePartnerships />,
	},
	{
		path: INITIATION_ROUTE + "/:id",
		Component: <InitiationPage />,
	},
	{
		path: RECOMENDATIONS_ROUTE + "/:id",
		Component: <RecommendationsPage />,
	},
	{
		path: VACANCIES_ROUTE + "/:id",
		Component: <VacanciesPage />,
	},
	{
		path: POLICIES_STRATEGIES_ROUTE,
		Component: <PoliciesStrategies />,
	},
	{
		path: POLICIES_STRATEGIES_ROUTE + "/:id",
		Component: <PoliciesStrategiesPage />,
	},
	{
		path: PUBLIC_TRANSPORT_NETWORK_ROUTE,
		Component: <PublicTransportNetwork />,
	},
	{
		path: PUBLIC_TRANSPORT_NETWORK_ROUTE + "/:id",
		Component: <PublicTransportNetworkPage />,
	},
	{
		path: PUBLIC_CONSULTATIONS_ROUTE,
		Component: <PublicConsultations />,
	},
	{
		path: PUBLIC_CONSULTATIONS_ROUTE + "/:id",
		Component: <ConsultationPage />,
	},
	{
		path: OTHER_ROUTE + "/:id",
		Component: <OtherPage />,
	},
	{
		path: HEAD_ONLINE_ROUTE + "/:id",
		Component: <HeadOnlinePage />,
	},
	{
		path: LOCATION_IN_NUMBERSS_ROUTE,
		Component: <LocationInNumbers />,
	},
	{
		path: SUCCESS_STORIES_ROUTE,
		Component: <SuccessStories />,
	},
	{
		path: LOCATION_DEVELOPMENT_STRATEGY_ROUTE,
		Component: <LocationDevelopmentStrategy />,
	},
	{
		path: KEY_SECTORS_ROUTE,
		Component: <KeySectors />,
	},
	{
		path: PARTNER_SUPPORTED_PROJECTS_ROUTE,
		Component: <PartnerSupportedProjects />,
	},
	{
		path: STARTING_BUSINESS_ROUTE,
		Component: <StartingBusiness />,
	},
	{
		path: TOURIST_ROUTES_ROUTE,
		Component: <TouristRoutes />,
	},
	{
		path: HOTELS_ROUTE,
		Component: <Hotels />,
	},
	{
		path: MUSEUMS_ROUTE,
		Component: <Museums />,
	},
	{
		path: PARKS_ROUTE,
		Component: <Parks />,
	},
	{
		path: TOURIST_ACTIVITIES_ROUTE,
		Component: <TouristActivities />,
	},
	{
		path: HISTORIC_BUILDINGS_ROUTE,
		Component: <HistoricBuildings />,
	},
	{
		path: MONUMENTS_ROUTE,
		Component: <Monuments />,
	},
	{
		path: NOTABLE_PERSONALITIES_ROUTE,
		Component: <NotablePersonalities />,
	},
	{
		path: FOR_KIDS_ROUTE,
		Component: <ForKids />,
	},
	{
		path: CHURCHES_MONASTERIES_ROUTE,
		Component: <ChurchesMonasteries />,
	},
	{
		path: REPORTS_ROUTE,
		Component: <Reports />,
	},
	{
		path: REPORTS_ROUTE + "/:id",
		Component: <ReportsPage />,
	},
	{
		path: INFORMATION_ROUTE,
		Component: <Information />,
	},
	{
		path: INFORMATION_ROUTE + "/:id",
		Component: <CECEInformationPage />,
	},
	{
		path: DECISIONS_ROUTE,
		Component: <Decisions />,
	},
	{
		path: DECISIONS_ROUTE + "/:id",
		Component: <CECEDecisionsPage />,
	},
	{
		path: ANNOUNCEMENTS_ROUTE,
		Component: <Announcements />,
	},
	{
		path: ANNOUNCEMENTS_ROUTE + "/:id",
		Component: <CECEAnnouncementsPage />,
	},
	{
		path: CECE_VERBAL_PROCEDURE_ROUTE,
		Component: <CECEVerbalProcedure />,
	},
	{
		path: CECE_VERBAL_PROCEDURE_ROUTE + "/:id",
		Component: <CECEVerbalProcedurePage />,
	},
	{
		path: LOWER_HIERARCHICAL_ORGANS_ROUTE,
		Component: <LowerHierarchicalOrgans />,
	},
	{
		path: PHOTO_GALLERY_ROUTE + "/:id",
		Component: <PhotoGalleryPage />,
	},
	{
		path: ONLINE_MEETING_ROUTE + "/:id",
		Component: <OnlineMeetingsPage />,
	},
	{
		path: MAIN_BANNER_INFO + "/:id",
		Component: <MainBannerInfoPage />,
	},
	{
		path: EVENT_PAGE_ROUTE + "/:id",
		Component: <EventPage />,
	},
	{
		path: EUROPEAN_PROJECTS_ROUTE,
		Component: <EuropeanProjects />,
	},
	{
		path: COMMITTEE_MEETINGS_ROUTE + "/:id",
		Component: <CommitteeMeetingsPage />,
	},
	{
		path: EUROPEAN_PROJECTS_ROUTE + "/:id",
		Component: <ProjectPage />,
	},
	{
		path: CITIZENS_HEARING_PROGRAM_ROUTE,
		Component: <CitizensHearingProgram />,
	},
	{
		path: ORGANIZATION_ROUTE,
		Component: <Organization />,
	},
	{
		path: ACQUISITION_ROUTE + "/:id",
		Component: <AcquisitionPage />,
	},
	{
		path: EXTRAORDINARY_COMMISSIONS_ROUTE,
		Component: <ExtraordinaryCommittees />,
	},

	{
		path: LD_GENERAL_ROUTE,
		Component: <LDGeneral />,
	},
	{
		path: LD_LOCAL_PUBLIC_ADMINISTRATION_ROUTE,
		Component: <LDLocalPublicAdministration />,
	},
	{
		path: LD_BUDGET_FINANCE_ROUTE,
		Component: <LDBudgetFinance />,
	},
	{
		path: LD_COMMERCE_ROUTE,
		Component: <LDCommerce />,
	},
	{
		path: LD_LOCAL_TAXES_ROUTE,
		Component: <LDLocalTaxes />,
	},
	{
		path: LD_CONSTRUCTION_ROUTE,
		Component: <LDConstruction />,
	},
	{
		path: LD_EMPLOYMENT_RELATIONSHIPS_ROUTE,
		Component: <LDEmploymentRelationships />,
	},
	{
		path: LD_TAND_TENURE_ROUTE,
		Component: <LDTandTenure />,
	},
	{
		path: LD_ORGANIZING_TENDERS_ROUTE,
		Component: <LDOrganizingTenders />,
	},
	{
		path: LD_MANAGING_PUBLIC_HERITAGE_ROUTE,
		Component: <LMDanagingPublicHeritage />,
	},
	{
		path: LD_OTHER_DOMAINS_ROUTE,
		Component: <LDOtherDomains />,
	},
];
