import { useEffect, useState } from "react";
import { Form, Spinner, Accordion, Card, Image, useAccordionButton, Tabs, Tab } from "react-bootstrap";
import "bootstrap-icons/font/bootstrap-icons.css";
import { observer } from "mobx-react-lite";
import parse from "html-react-parser";
import { EXTRAORDINARY_COMMISSIONS_ROUTE } from "../../utils/consts";
import {
    fetchOneExtraordinaryCommissions,
    removeOneExtraordinaryCommissions,
    updateOneExtraordinaryCommissions,
} from "../../http/resourcesServicesAPI";
import AddExtraordinaryCommissionsModal from "./Modals/AddExtraordinaryCommissionsModal";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const ExtraordinaryCommissionsAdmin = observer(() => {
    const [show, setShow] = useState(false);

    const [extraComm, setExtraComm] = useState(null);

    const [loading, setLoading] = useState(true);
    const [selectedTab, setSelectedTab] = useState("Comisii Extraordinare");

    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [author, setAuthor] = useState("");
    const [img, setImg] = useState([null]);

    const CustomToggle = ({ children, eventKey }) => {
        const decoratedOnClick = useAccordionButton(eventKey);

        return (
            <button
                className="btn btn-primary d-flex align-items-center mb-xl-1 mx-xl-0 mx-2"
                onClick={decoratedOnClick}
            >
                {children}
            </button>
        );
    };

    const editItem = (data) => {
        const id = data.id;
        const formData = new FormData();
        formData.append("title", title);
        formData.append("description", description);
        formData.append("img", img);
        formData.append("author", author);

        switch (selectedTab) {
            case "Comisii Extraordinare":
                updateOneExtraordinaryCommissions(formData, id).then(() => window.location.reload());
                break;
        }
    };

    const modules = {
        toolbar: [
            [{ size: ["small", false, "large", "huge"] }],
            ["bold", "italic", "underline", "strike", "blockquote"],
            [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }, { align: [] }],
            ["link", "image"],
            [
                {
                    color: [
                        "#000000",
                        "#e60000",
                        "#ff9900",
                        "#ffff00",
                        "#008a00",
                        "#0066cc",
                        "#9933ff",
                        "#ffffff",
                        "#facccc",
                        "#ffebcc",
                        "#ffffcc",
                        "#cce8cc",
                        "#cce0f5",
                        "#ebd6ff",
                        "#bbbbbb",
                        "#f06666",
                        "#ffc266",
                        "#ffff66",
                        "#66b966",
                        "#66a3e0",
                        "#c285ff",
                        "#888888",
                        "#a10000",
                        "#b26b00",
                        "#b2b200",
                        "#006100",
                        "#0047b2",
                        "#6b24b2",
                        "#444444",
                        "#5c0000",
                        "#663d00",
                        "#666600",
                        "#003700",
                        "#002966",
                        "#3d1466",
                        "custom-color",
                    ],
                },
            ],
        ],
    };

    const formats = [
        "header",
        "height",
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        "list",
        "color",
        "bullet",
        "indent",
        "link",
        "image",
        "align",
        "size",
    ];

    const removeItem = (item) => {
        const id = item.id;
        switch (selectedTab) {
            case "Comisii Extraordinare":
                removeOneExtraordinaryCommissions(id).then(() => window.location.reload());
                break;
        }
    };

    const selectFile = (e) => {
        setImg(e.target.files[0]);
    };

    useEffect(() => {
        fetchOneExtraordinaryCommissions(1)
            .then((data) => {
                setExtraComm(data);
            })
            .then(() => setLoading(false));
    }, []);

    if (loading) {
        return (
            <div className="d-flex align-items-center justify-content-center py-5">
                <Spinner animation={"grow"} />
            </div>
        );
    }

    return (
        <div className="d-flex flex-column bg-white r-corner-radius w-100">
            <div className="d-flex justify-content-between align-items-center font-2 fw-bold text-primary shadow-sm ps-3">
                <span>Comisii Extraordinare</span>
                <button
                    className="btn btn-primary r-u-corner-radius"
                    style={{ height: 70, width: 70 }}
                    onClick={() => setShow(true)}
                >
                    <i className="bi bi-plus-circle icon-2" />
                </button>
            </div>
            <div className="d-flex flex-column px-2 pt-3">
                <Tabs
                    defaultActiveKey="Comisii Extraordinare"
                    id="uncontrolled-tab-example"
                    className="d-flex justify-content-center mb-3 text-primary fw-bold"
                    onSelect={(e) => setSelectedTab(e)}
                >
                    <Tab eventKey="Comisii Extraordinare" title="Comisii Extraordinare">
                        <div className="d-flex flex-column">
                            <Accordion className="pb-4">
                                {extraComm ? (
                                    <div className="shadow-sm border-0 my-2 px-4 rounded">
                                        <div className="d-flex flex-column">
                                            <div className="d-flex flex-xl-row flex-column align-items-center justify-content-between  p-3">
                                                <div className="d-flex flex-xl-row flex column align-items-center">
                                                    {extraComm.img ? (
                                                        <Image
                                                            className="rounded"
                                                            src={process.env.REACT_APP_API_URL + extraComm.img}
                                                            style={{ maxHeight: "30vh" }}
                                                        />
                                                    ) : null}
                                                    <div className="d-flex flex-column  ps-xl-4">
                                                        <a
                                                            role="button"
                                                            href={EXTRAORDINARY_COMMISSIONS_ROUTE}
                                                            className="text-decoration-none text-primary"
                                                        >
                                                            <Card.Title>{extraComm.title}</Card.Title>
                                                        </a>
                                                        <div className="truncate pe-4">
                                                            {extraComm.description
                                                                ? parse(extraComm.description)
                                                                : null}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="d-flex flex-xl-column flex-row justify-content-around py-xl-0 py-3">
                                                    <CustomToggle
                                                        eventKey="mayor_message"
                                                        onClick={() => {
                                                            useAccordionButton("mayor_message");
                                                        }}
                                                    >
                                                        <i className="bi bi-pencil-square icon-2 pe-2" />
                                                        <span>Edit</span>
                                                    </CustomToggle>
                                                    <button
                                                        className="btn btn-primary d-flex align-items-center mx-xl-0 mx-2"
                                                        onClick={() => removeItem(extraComm)}
                                                    >
                                                        <i className="bi bi-x-circle icon-2 pe-2" />
                                                        <span>Remove</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <Accordion.Collapse eventKey="mayor_message">
                                            <div className="d-flex flex-column justify-content-around px-4 py-4 border-top">
                                                <div className="d-flex flex-column justify-content-around px-4 py-4">
                                                    <span className="text-primary  font-2 py-2 ps-1">Denumire</span>
                                                    <Form.Control
                                                        aria-label="Title"
                                                        placeholder={extraComm.title}
                                                        value={title}
                                                        onChange={(e) => {
                                                            setTitle(e.target.value);
                                                        }}
                                                    />
                                                    {/* <span className='text-primary  font-2 py-2 ps-1'>Descriere</span>
													<Form.Control
														aria-label='Title'
														placeholder={extraComm.description}
														value={description}
														onChange={(e) => {
															setDescription(e.target.value);
														}}
													/> */}
                                                    <span className="text-primary  font-2 py-2 ps-1">Descriere</span>
                                                    <ReactQuill
                                                        theme="snow"
                                                        value={description}
                                                        modules={modules}
                                                        formats={formats}
                                                        onChange={setDescription}
                                                        placeholder={extraComm.description}
                                                    />
                                                    <span className="text-primary  font-2 py-2 ps-1">Autor</span>
                                                    <Form.Control
                                                        aria-label="Title"
                                                        placeholder={extraComm.author}
                                                        value={author}
                                                        onChange={(e) => {
                                                            setAuthor(e.target.value);
                                                        }}
                                                    />
                                                    <span className="text-primary  font-2 py-2 ps-1">Fotografie</span>
                                                    <Form.Control
                                                        type={"file"}
                                                        onChange={selectFile}
                                                        aria-label="PageTitle"
                                                        aria-describedby="basic-addon"
                                                    />
                                                </div>
                                                <button
                                                    className="btn btn-primary d-flex align-items-center justify-content-center mx-xl-0 my-4"
                                                    onClick={() => editItem(extraComm)}
                                                >
                                                    <i className="bi bi-check icon-2 pe-2" />
                                                    <span>Confirm</span>
                                                </button>
                                            </div>
                                        </Accordion.Collapse>
                                    </div>
                                ) : null}
                            </Accordion>
                        </div>
                    </Tab>
                </Tabs>
            </div>
            <AddExtraordinaryCommissionsModal show={show} onHide={() => setShow(false)} selectedTab={selectedTab} />
        </div>
    );
});

export default ExtraordinaryCommissionsAdmin;
