import { useState } from "react";
import { Form, Modal, Button, Col, Row } from "react-bootstrap";
import { createReferences } from "../../../http/referencesAPI";
import {
	createCityNews,
	createCommitteeMeetings,
	createMayorOnline,
	createOnlineMeetings,
	createOther,
	createPhotoGallery,
} from "../../../http/NewsAPI";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const AddCityNewsModal = ({ show, onHide, selectedTab }) => {
	const [title, setTitle] = useState("");
	const [article, setArticle] = useState("");
	const [link, setLink] = useState("");
	const [file, setFile] = useState([null]);
	const [author, setAuthor] = useState("");
	const [multiplePhotos, setMultiplePhotos] = useState([]);

	const addMultiplePhotosElement = () => {
		setMultiplePhotos([...multiplePhotos, { img: [null], img_description: "", number: Date.now() }]);
	};
	const removeMultiplePhotosElement = (number) => {
		setMultiplePhotos(multiplePhotos.filter((i) => i.number !== number));
	};

	const changeMultiplePhotosElement = (key, value, number) => {
		setMultiplePhotos(multiplePhotos.map((i) => (i.number === number ? { ...i, [key]: value } : i)));
	};

	const addItem = () => {
		const formData = new FormData();
		formData.append("title", title);
		formData.append("img", file);
		formData.append("article", article);
		formData.append("link", link);
		formData.append("author", author);
		// formData.append("files", files);

		// formData.append("filesTest", JSON.stringify(multiplePhotos));

		for (const file of multiplePhotos) {
			formData.append("files", file.img);
			formData.append("files_description", file.img_description);
		}
		switch (selectedTab) {
			case "Noutăți":
				createCityNews(formData).then(() => {
					onHide();
					window.location.reload();
				});
				break;
			case "Ședințe comisii":
				createCommitteeMeetings(formData).then(() => {
					onHide();
					window.location.reload();
				});
				break;
			case "Fotografii":
				createPhotoGallery(formData).then(() => {
					onHide();
					window.location.reload();
				});
				break;
			case "Ședințe online":
				createOnlineMeetings(formData).then(() => {
					onHide();
					window.location.reload();
				});
				break;
			case "Primarul online":
				createMayorOnline(formData).then(() => {
					onHide();
					window.location.reload();
				});
				break;
			case "Alte":
				createOther(formData).then(() => {
					onHide();
					window.location.reload();
				});
				break;
		}
	};

	const selectFile = (e) => {
		setFile(e.target.files[0]);
	};

	const modules = {
		toolbar: [
			[{ size: ["small", false, "large", "huge"] }],
			["bold", "italic", "underline", "strike", "blockquote"],
			[{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }, { align: [] }],
			["link", "image"],
			[
				{
					color: [
						"#000000",
						"#e60000",
						"#ff9900",
						"#ffff00",
						"#008a00",
						"#0066cc",
						"#9933ff",
						"#ffffff",
						"#facccc",
						"#ffebcc",
						"#ffffcc",
						"#cce8cc",
						"#cce0f5",
						"#ebd6ff",
						"#bbbbbb",
						"#f06666",
						"#ffc266",
						"#ffff66",
						"#66b966",
						"#66a3e0",
						"#c285ff",
						"#888888",
						"#a10000",
						"#b26b00",
						"#b2b200",
						"#006100",
						"#0047b2",
						"#6b24b2",
						"#444444",
						"#5c0000",
						"#663d00",
						"#666600",
						"#003700",
						"#002966",
						"#3d1466",
						"custom-color",
					],
				},
			],
		],
	};

	const formats = [
		"header",
		"height",
		"bold",
		"italic",
		"underline",
		"strike",
		"blockquote",
		"list",
		"color",
		"bullet",
		"indent",
		"link",
		"image",
		"align",
		"size",
	];

	return (
		<Modal
			show={show}
			onHide={onHide}
			className='add-news-modal border-primary'>
			<Modal.Header
				closeButton={onHide}
				className='bg-primary'
				closeVariant='white'>
				<Modal.Title className='text-white'>{selectedTab}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className='d-flex flex-column justify-content-around'>
					{selectedTab === "Noutăți" || selectedTab === "Fotografii" ? (
						<>
							<span className='text-primary  font-2 py-2 ps-1'>Fotografii</span>
							<Button
								variant={"primary"}
								onClick={addMultiplePhotosElement}>
								Adauga o Fotografie
							</Button>
							{multiplePhotos.map((i) => (
								<div
									className={"d-flex flex-md-row flex-column align-items-center justify-content-around mt-4 w-100"}
									key={i.number}>
									<div className='my-1'>
										<Form.Control
											type='file'
											onChange={(e) => {
												changeMultiplePhotosElement("img", e.target.files[0], i.number);
											}}
										/>
									</div>
									<div className='my-1'>
										<Form.Control
											value={i.img_description}
											onChange={(e) => changeMultiplePhotosElement("img_description", e.target.value, i.number)}
											placeholder={"Adaugati descrierea"}
										/>
									</div>
									<div className='my-1'>
										<Button
											variant={"outline-danger"}
											onClick={() => removeMultiplePhotosElement(i.number)}>
											Sterge
										</Button>
									</div>
								</div>
							))}
							{/* <span className="text-primary  font-2 py-2 ps-1">Fotografii</span>
							<Form.Control
								type={"file"}
								onChange={selectMultipleFiles}
								aria-label="Photos"
								aria-describedby="basic-addon"
								multiple
							/> */}
						</>
					) : null}
					{selectedTab === "Ședințe comisii" ? (
						<>
							<span className='text-primary  font-2 py-2 ps-1'>Fotografie</span>
							<Form.Control
								type={"file"}
								onChange={selectFile}
								aria-label='Photo'
								aria-describedby='basic-addon'
							/>
						</>
					) : null}
					<span className='text-primary  font-2 py-2 ps-1'>Denumire</span>
					<Form.Control
						aria-label='Title'
						placeholder='Denumire'
						value={title}
						onChange={(e) => setTitle(e.target.value)}
					/>
					<span className='text-primary  font-2 py-2 ps-1'>Descriere</span>
					<ReactQuill
						theme='snow'
						value={article}
						modules={modules}
						formats={formats}
						onChange={setArticle}
					/>
					{selectedTab !== "Noutăți" && selectedTab !== "Fotografii" ? (
						<>
							<span className='text-primary  font-2 py-2 ps-1'>Lincul catre resurs</span>
							<Form.Control
								aria-label='Link'
								placeholder='Lincul catre resurs'
								value={link}
								onChange={(e) => setLink(e.target.value)}
							/>
						</>
					) : null}
					<span className='text-primary  font-2 py-2 ps-1'>Autor</span>
					<Form.Control
						aria-label='Author'
						placeholder='Autor'
						value={author}
						onChange={(e) => setAuthor(e.target.value)}
					/>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<Button
					variant='secondary'
					onClick={onHide}>
					Close
				</Button>
				<Button
					variant='primary'
					onClick={() => {
						onHide();
						addItem();
					}}>
					Confirm
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default AddCityNewsModal;
