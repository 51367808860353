import { useContext, useState, useEffect } from "react";
import { Context } from "..";
import Calendar from "../components/Calendar";
import { fetchRegulations } from "../http/navLinksAPI";
import { Spinner, Accordion } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { REGULATIONS_ROUTE } from "../utils/consts";

const Regulations = () => {
	const { regulations } = useContext(Context);
	const navigate = useNavigate();
	const [state, setState] = useState(false);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		fetchRegulations()
			.then((data) => {
				regulations.setRegulations(data);
			})
			.finally(() => setLoading(false));
	}, []);
	if (loading) {
		return (
			<div className='d-flex align-items-center justify-content-center py-5'>
				<Spinner animation={"grow"} />
			</div>
		);
	}
	return (
		<div className='d-flex flex-md-row flex-column justify-content-around px-4 border-bottom'>
			<div className='d-flex flex-column px-5 py-4 col-md-8'>
				<div className='block-title pb-4'>
					<span>Regulamente</span>
				</div>
				<div className='d-flex flex-column align-items-center'>
					<Accordion className='pb-4 w-100'>
						{regulations.regulations.count > 0
							? regulations.regulations.rows.map((item, idx) =>
									item.regulations_files.length > 0 ? (
										<Accordion.Item
											eventKey={"regulation-" + idx}
											key={"regulation-" + idx}
											className='border-0'>
											<Accordion.Header>
												<Link
													className='fw-bold ps-xl-1 font-2 text-primary text-justify pe-4 text-decoration-none'
													to={REGULATIONS_ROUTE + "/" + item.id}>
													{item.title}
												</Link>
											</Accordion.Header>
											<div className='d-flex justify-content-start py-1 ps-4'>
												<span className='border rounded border-primary px-2 text-primary'>{item.date}</span>
											</div>
											<Accordion.Body>
												{item.regulations_files.map((sub_item, sub_idx) => (
													<Link
														to={process.env.REACT_APP_API_URL + sub_item.doc}
														key={sub_idx}
														className='text-primary text-decoration-none'>
														<div className='d-flex align-items-center'>
															<i
																className={`bi bi-filetype-${sub_item.doc_type} icon-2 text-primary rounded ms-4 px-2 text-primary`}
															/>
															<span
																className='text-wrap'
																style={{ textAlign: "justify" }}>
																{sub_item.doc.split(".")[0]}
															</span>
														</div>
													</Link>
												))}
											</Accordion.Body>
										</Accordion.Item>
									) : (
										<div className='d-flex flex-column mb-4 ps-4 ps-xl-0'>
											<Link
												className='fw-bold font-2 ps-xl-4 text-primary text-justify pe-4 text-decoration-none'
												to={REGULATIONS_ROUTE + "/" + item.id}>
												{item.title}
											</Link>
											<div className='d-flex justify-content-start py-2 ps-xl-4 pt-3'>
												<span className='border rounded border-primary px-2 text-primary'>{item.date}</span>
											</div>
										</div>
									)
							  )
							: null}
					</Accordion>
				</div>
			</div>
			<Calendar />
		</div>
	);
};

export default Regulations;
