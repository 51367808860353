import { useContext, useState } from "react";
import { Button, Card, Container, Form, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { login, registration } from "../http/userAPI";
import { observer } from "mobx-react-lite";
import { Context } from "../index";
import { ADMIN_ROUTE, HOME_ROUTE, LOGIN_ROUTE } from "../utils/consts";

const Auth = observer(() => {
	const { user } = useContext(Context);
	const navigate = useNavigate();
	const location = useLocation();
	const isLogin = location.pathname === LOGIN_ROUTE;
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");

	const click = async () => {
		try {
			let data;
			if (isLogin) {
				data = await login(email, password);
			}
			// else {
			// 	data = await registration(email, password);
			// }
			user.setUser(user);
			user.setIsAuth(true);
			navigate(ADMIN_ROUTE);
		} catch (e) {
			alert(e.response.data.message);
		}
	};

	return (
		<div
			className='d-flex justify-content-center align-items-center'
			style={{ marginTop: "20vh", marginBottom: "20vh" }}>
			<Card className='p-4 w-75 shadow border-0'>
				{/* <h4 className='m-auto text-primary'>{isLogin ? "Authorization" : "Registration"}</h4> */}
				<h4 className='m-auto text-primary'>Authorization</h4>
				<Form className={"d-flex flex-column"}>
					<Form.Control
						className='mt-4'
						type='email'
						placeholder={"Introduceți adresa de e-mail"}
						value={email}
						onChange={(e) => setEmail(e.target.value)}
					/>
					<Form.Control
						className='my-3'
						placeholder={"Introduceți parola"}
						value={password}
						autoComplete='current-password'
						onChange={(e) => setPassword(e.target.value)}
						type={"password"}
					/>
					<Button
						variant={"outline-primary"}
						onClick={click}>
						{/* {isLogin ? "Login" : "Registration"} */}
						Login
					</Button>
					{/* <Row className={"d-flex justify-content-between align-items-center mt-3 pe-3 ps-1 row-cols-auto"}>
                        {isLogin ? (
                            <div className={"d-flex"}>
                                New customer?{" "}
                                <NavLink
                                    style={{ marginLeft: 8, textDecoration: "underline", fontWeight: "500" }}
                                    to={REGISTRATION_ROUTE}
                                >
                                    Sign up
                                </NavLink>
                            </div>
                        ) : (
                            <div className={"d-flex"}>
                                Have account?{" "}
                                <NavLink
                                    style={{ marginLeft: 8, textDecoration: "underline", fontWeight: "500" }}
                                    to={LOGIN_ROUTE}
                                >
                                    Sign in
                                </NavLink>
                            </div>
                        )}
                        <Button variant={"outline-success"} onClick={click}>
                            {isLogin ? "Login" : "Registration"}
                        </Button>
                    </Row> */}
				</Form>
			</Card>
		</div>
	);
});

export default Auth;
