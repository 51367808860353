import React from "react";
import Calendar from "../components/Calendar";

const LDBudgetFinance = () => {
	return (
		<div className='d-flex flex-xl-row flex-column justify-content-around px-4 border-bottom'>
			<div className='d-flex flex-column px-xl-5 py-4 col-xl-9 col-12'>
				<div className='block-title pb-4'>
					<span>Buget şi finanţe</span>
				</div>

				<div className='d-flex flex-column justify-content-start shadow rounded'>
					<iframe
						id='widget_iframe'
						className='rounded'
						style={{
							borderWidth: 0,
							width: "100%",
							height: 1000,
							backgroundColor: "white",
							borderColor: "black",
							borderRadius: 0,
						}}
						src='https://actelocale.gov.md/widget_src/eyJzaG93X3BhZ2luYXRpb24iOnt9LCJzaG93X3NlYXJjaCI6dHJ1ZSwicmVzdWx0c19vbl9wYWdlIjoyMCwib2Zmc2V0IjowLCJhcGwiOiIxODUwNyIsImRhdGVfcmFuZ2UiOnsiZGF0ZV9mcm9tIjoiIiwiZGF0ZV90byI6IiJ9LCJzdGF0ZXMiOltdLCJkb21haW5zIjpbMl0sInR5cGVzIjpbXX0%3D'
					/>
				</div>
			</div>
			<Calendar />
		</div>
	);
};

export default LDBudgetFinance;
