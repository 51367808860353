import { useState, useContext, useEffect } from "react";
import "react-calendar/dist/Calendar.css";
import Calendar from "react-calendar";
import { Card, Button } from "react-bootstrap";
import { Context } from "..";
import { EVENT_PAGE_ROUTE, EVENTS_ROUTE } from "../utils/consts";
import { Link, useNavigate } from "react-router-dom";
import { fetchCalendarEvents } from "../http/calendarAPI";
import { Spinner } from "react-bootstrap";

const CalendarComponent = () => {
    const navigate = useNavigate();
    const { calendarEvents } = useContext(Context);
    const [selectedDate, setSelectedDate] = useState(null);
    const [displayEventList, setDisplayEventList] = useState(false);

    const Date_Click_Fun = (date) => {
        setSelectedDate(date);
        setDisplayEventList(true);
    };

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchCalendarEvents()
            .then((data) => {
                calendarEvents.setEvent(data);
            })
            .finally(() => setLoading(false));
    }, [calendarEvents]);
    if (loading) {
        return (
            <div className="d-flex align-items-center justify-content-center py-5">
                <Spinner animation={"grow"} />
            </div>
        );
    }

    return (
        <div className="d-flex flex-column justify-content-baseline">
            <div className="py-4 block-title text-center">
                <Link
                    role="button"
                    to={EVENTS_ROUTE}
                    className="text-primary text-decoration-none border-bottom border-2 border-primary fw-normal font-onest"
                >
                    Calendar activități
                </Link>
            </div>
            <div className="d-flex justify-content-center">
                <div className="calendar-container">
                    <Calendar
                        value={selectedDate}
                        onClickDay={Date_Click_Fun}
                        className="rounded border-0 shadow col-9"
                        tileClassName={({ date }) =>
                            selectedDate && date === selectedDate.toDateString()
                                ? "selected"
                                : calendarEvents.events.rows.some((event) => event.ps_date === date.toDateString())
                                ? "event-marked"
                                : ""
                        }
                    />
                </div>

                {calendarEvents.events.rows.length > 0 && selectedDate && displayEventList && (
                    <div
                        className="bg-white text-primary  rounded position-absolute shadow border border-primary"
                        style={{ width: 310, marginTop: "280px", zIndex: 999 }}
                    >
                        {calendarEvents.events.rows.map((event, idx) =>
                            event.ps_date === selectedDate.toDateString() ? (
                                <div className="d-flex flex-column px-3 py-2 rounded" key={idx}>
                                    <div className="d-flex flex-column text-primary">
                                        <Link to={EVENT_PAGE_ROUTE + "/" + event.id} className="fw-bold text-primary">
                                            <Link
                                                className="event-title text-primary"
                                                role="button"
                                                to={EVENT_PAGE_ROUTE + "/" + event.id}
                                            >
                                                {event.title}
                                            </Link>
                                        </Link>
                                        <div className="event-time">{event.time}</div>
                                        {/* <div className='event-organizer'>{event.organizer}</div> */}
                                    </div>
                                </div>
                            ) : null
                        )}
                    </div>
                )}
            </div>
            <div className="d-flex flex-column text-center justify-content-between">
                <span className="py-4 fs-4">Legendă</span>
                <div className="d-flex flex-row flex-wrap align-items-center justify-content-center">
                    <div className="d-flex flex-row align-items-center justify-content-around px-4">
                        <div style={{ height: 20, width: 20, backgroundColor: "#AFAFAF" }}></div>
                        <span className="ps-2">Data curentă</span>
                    </div>
                    <div className="d-flex flex-row align-items-center justify-content-around px-4">
                        <div style={{ height: 20, width: 20, backgroundColor: "#00549c" }}></div>
                        <span className="ps-2">Evenimente</span>
                    </div>
                </div>
                <div className="d-flex flex-column align-items-center justify-content-between">
                    {calendarEvents.events.count === 1 ? (
                        <Card className="my-3 mx-2 border-0 col-12">
                            <Card.Body className="p-2 d-flex align-items-center flex-column">
                                <Card.Title className="mb-3 d-flex align-items-center justify-content-between">
                                    <span
                                        className="border border-primary rounded text-primary p-2"
                                        style={{ fontSize: "1rem" }}
                                    >
                                        {calendarEvents.events.rows[0].date + " " + calendarEvents.events.rows[0].time}
                                    </span>
                                    {/* <span
                                    className='border border-danger rounded text-danger p-2'
                                    style={{ fontSize: "1rem" }}>
                                    NEW
                                </span> */}
                                </Card.Title>
                                <Card.Text className="mb-0">{calendarEvents.events.rows[0].title}</Card.Text>
                                <Card.Footer className="bg-white border-0 py-0 my-0 px-0 text-start">
                                    <small className="text-primary">{calendarEvents.events.rows[0].organizer}</small>
                                </Card.Footer>
                            </Card.Body>
                        </Card>
                    ) : calendarEvents.events.count > 1 ? (
                        <>
                            <Card className="my-3 mx-2 border-0 col-12">
                                <Card.Body className="p-2 d-flex align-items-center flex-column">
                                    <Card.Title className="mb-3 d-flex align-items-center justify-content-between">
                                        <span
                                            className="border border-primary rounded text-primary p-2"
                                            style={{ fontSize: "1rem" }}
                                        >
                                            {calendarEvents.events.rows[calendarEvents.events.rows.length - 1].date +
                                                " " +
                                                calendarEvents.events.rows[calendarEvents.events.rows.length - 1].time}
                                        </span>
                                        {/* <span
                                className='border border-danger rounded text-danger p-2'
                                style={{ fontSize: "1rem" }}>
                                NEW
                            </span> */}
                                    </Card.Title>
                                    <Card.Text className="mb-0">
                                        {calendarEvents.events.rows[calendarEvents.events.rows.length - 1].title}
                                    </Card.Text>
                                    <Card.Footer className="bg-white border-0 py-0 my-0 px-0 text-start">
                                        <small className="text-primary">
                                            {
                                                calendarEvents.events.rows[calendarEvents.events.rows.length - 1]
                                                    .organizer
                                            }
                                        </small>
                                    </Card.Footer>
                                </Card.Body>
                            </Card>
                            <Card className="my-3 mx-2 border-0 col-12">
                                <Card.Body className="p-2 d-flex align-items-center flex-column">
                                    <Card.Title className="mb-3 d-flex align-items-center justify-content-between">
                                        <span
                                            className="border border-primary rounded text-primary p-2"
                                            style={{ fontSize: "1rem" }}
                                        >
                                            {calendarEvents.events.rows[calendarEvents.events.rows.length - 2].date +
                                                " " +
                                                calendarEvents.events.rows[calendarEvents.events.rows.length - 2].time}
                                        </span>
                                        {/* <span
                                className='border border-danger rounded text-danger p-2'
                                style={{ fontSize: "1rem" }}>
                                NEW
                            </span> */}
                                    </Card.Title>
                                    <Card.Text className="mb-0">
                                        {calendarEvents.events.rows[calendarEvents.events.rows.length - 2].title}
                                    </Card.Text>
                                    <Card.Footer className="bg-white border-0 py-0 my-0 px-0 text-start">
                                        <small className="text-primary">
                                            {
                                                calendarEvents.events.rows[calendarEvents.events.rows.length - 2]
                                                    .organizer
                                            }
                                        </small>
                                    </Card.Footer>
                                </Card.Body>
                            </Card>
                        </>
                    ) : null}
                </div>
                <div className="d-flex justify-content-center align-items-center my-3 text-decoration-none text-primary font-2">
                    <Link
                        className="bg-primary text-decoration-none text-white py-2 px-3 rounded d-flex align-items-center font-2"
                        to={EVENTS_ROUTE}
                    >
                        Vezi mai mult <i className="bi bi-arrow-right ps-3 icon-2" />
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default CalendarComponent;
