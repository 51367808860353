import { useState, useEffect, useRef } from "react";
import Calendar from "../components/Calendar";
import { Spinner } from "react-bootstrap";
import { Table } from "react-bootstrap";
import { fetchSpecialCommittees } from "../http/navLinksAPI";
import ShareButton from "../components/UI/ShareButton";

const SpecializedCommittees = () => {
	const [committees, setCommittees] = useState();
	const [loading, setLoading] = useState(true);

	const printRef = useRef(null);
	const handlePrint = () => {
		const originalBodyContent = document.body.innerHTML;
		document.body.innerHTML = printRef.current.outerHTML;
		window.print();

		onafterprint = (e) => {
			console.log(e);

			document.body.innerHTML = originalBodyContent;
		};
		window.location.reload();
	};

	useEffect(() => {
		fetchSpecialCommittees()
			.then((data) => {
				setCommittees(data);
			})
			.finally(() => setLoading(false));
	}, []);
	if (loading) {
		return (
			<div className='d-flex align-items-center justify-content-center py-5'>
				<Spinner animation={"grow"} />
			</div>
		);
	}

	return (
		<div>
			<div className='d-flex flex-xl-row flex-column justify-content-center px-xl-4 px-2'>
				<div
					className='d-flex flex-column col-xl-8 px-xl-4'
					ref={printRef}>
					<div className='py-4 block-title text-center'>
						<span className='text-primary'>Componenţa nominală a comisiilor de specialitate în Consiliul raional Edineț</span>
					</div>

					{committees.count > 0
						? committees.rows.map((item, idx) => (
								<div key={idx}>
									<div className='py-4 block-title text-center font-2 py-4'>
										<span className='text-primary'>{item.committeeTitle}</span>
									</div>
									<Table
										bordered
										hover>
										<thead>
											<tr>
												<th
													style={{ verticalAlign: "middle" }}
													className='bg-primary text-white'>
													#
												</th>
												<th
													style={{ verticalAlign: "middle" }}
													className='bg-primary text-white'>
													Numele, Prenumele
												</th>
												<th
													style={{ verticalAlign: "middle" }}
													className='bg-primary text-white'>
													Partidul
												</th>
											</tr>
										</thead>
										<tbody>
											{item.special_committees_members.map((sub_item, sub_idx) => (
												<tr key={idx}>
													<td style={{ verticalAlign: "middle" }}>{sub_idx + 1}</td>
													<td style={{ verticalAlign: "middle" }}>{sub_item.full_name}</td>
													<td style={{ verticalAlign: "middle" }}>{sub_item.party_name}</td>
												</tr>
											))}
										</tbody>
									</Table>
								</div>
						  ))
						: null}

					<div className='d-flex flex-xl-row flex-column align-items-center justify-content-end w-100'>
						<i
							role='button'
							onClick={() => handlePrint()}
							className='bi bi-printer text-primary icon-3 px-4'
							style={{
								fontWeight: "600 !important",
								WebkitTextStroke: 1,
							}}
						/>
						<ShareButton />
					</div>
				</div>
				<Calendar />
			</div>
		</div>
	);
};

export default SpecializedCommittees;
