import { useState, useEffect, useRef } from "react";
import Calendar from "../components/Calendar";
import { Image } from "react-bootstrap";
import { Spinner } from "react-bootstrap";
import { fetchOrganization } from "../http/navLinksAPI";
import parse from "html-react-parser";
import ShareButton from "../components/UI/ShareButton";

const Organization = () => {
	const [organization, setOrganization] = useState();
	const [loading, setLoading] = useState(true);

	const printRef = useRef(null);
	const handlePrint = () => {
		const originalBodyContent = document.body.innerHTML;
		document.body.innerHTML = printRef.current.outerHTML;
		window.print();

		onafterprint = (e) => {
			console.log(e);

			document.body.innerHTML = originalBodyContent;
			window.location.reload();
		};
	};

	useEffect(() => {
		fetchOrganization()
			.then((data) => {
				setOrganization(data);
			})
			.finally(() => setLoading(false));
	}, []);
	if (loading) {
		return (
			<div className='d-flex align-items-center justify-content-center py-5'>
				<Spinner animation={"grow"} />
			</div>
		);
	}
	return (
		<div>
			<div className='d-flex flex-xl-row flex-column justify-content-center px-xl-4 px-2'>
				<div
					className='d-flex flex-column col-xl-8 px-xl-4'
					ref={printRef}>
					<div className='py-4 block-title text-center'>
						<span className='text-primary'>Organigrama</span>
					</div>
					{organization.count > 0 ? (
						<>
							{organization.rows.map((item, idx) => (
								<div
									className='d-flex flex-column justify-content-center'
									key={idx}>
									<Image
										src={process.env.REACT_APP_API_URL + item.img}
										style={{
											maxHeight: "50vh",
											width: "auto",
											aspectRatio: 1,
											objectFit: "contain",
										}}
									/>
									{item.description ? (
										<span className='py-2 text-justify text-wrap'>{parse(item.description)}</span>
									) : null}
								</div>
							))}
							<div className='d-flex flex-xl-row flex-column align-items-center justify-content-end w-100'>
								<div className='d-flex flex-row align-items-center justify-content-end ps-1'>
									<i
										role='button'
										onClick={() => handlePrint()}
										className='bi bi-printer text-primary icon-3'
										style={{
											fontWeight: "600 !important",
											WebkitTextStroke: 1,
										}}
									/>
									<ShareButton />
								</div>
							</div>
						</>
					) : null}
				</div>
				<Calendar />
			</div>
		</div>
	);
};

export default Organization;
