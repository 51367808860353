import { useEffect, useState, useContext } from "react";
import {
  Form,
  Spinner,
  Accordion,
  Card,
  Image,
  useAccordionButton,
  Tabs,
  Tab,
} from "react-bootstrap";
import "bootstrap-icons/font/bootstrap-icons.css";
import { observer } from "mobx-react-lite";
import { Context } from "../../index";
import { useNavigate } from "react-router-dom";
import {
  ANNOUNCEMENTS_ROUTE,
  CECE_VERBAL_PROCEDURE_ROUTE,
  DECISIONS_ROUTE,
  INFORMATION_ROUTE,
  REPORTS_ROUTE,
} from "../../utils/consts";
import parse from "html-react-parser";
import {
  fetchCECEAnnouncements,
  fetchCECEDecisions,
  fetchCECEInformation,
  fetchCECEVerbalProcedure,
  fetchLowerHierarchicalOrgans,
  fetchReports,
  removeOneCECEAnnouncements,
  removeOneCECEAnnouncementsFile,
  removeOneCECEDecisions,
  removeOneCECEDecisionsFile,
  removeOneCECEInformation,
  removeOneCECEInformationFile,
  removeOneCECEVerbalProcedure,
  removeOneCECEVerbalProcedureFile,
  removeOneLowerHierarchicalOrgans,
  removeOneReports,
  removeOneReportsFile,
  updateOneCECEAnnouncements,
  updateOneCECEDecisions,
  updateOneCECEInformation,
  updateOneCECEVerbalProcedure,
  updateOneLowerHierarchicalOrgans,
  updateOneReports,
} from "../../http/resourcesServicesAPI";
import AddCECEModal from "./Modals/AddCECEModal";

const CECEAdmin = observer(() => {
  const { resourcesServices } = useContext(Context);
  const [show, setShow] = useState(false);

  const [loading, setLoading] = useState(true);
  const [selectedTab, setSelectedTab] = useState("Rapoarte");

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [img, setImg] = useState([null]);
  const [files, setFiles] = useState([null]);

  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [time, setTime] = useState("");
  const [schedule, setSchedule] = useState("");
  const [lunch, setLunch] = useState("");

  const navigate = useNavigate();

  const CustomToggle = ({ children, eventKey }) => {
    const decoratedOnClick = useAccordionButton(eventKey);

    return (
      <button
        className="btn btn-primary d-flex align-items-center mb-xl-1 mx-xl-0 mx-2"
        onClick={decoratedOnClick}
      >
        {children}
      </button>
    );
  };

  const editItem = (data) => {
    const id = data.id;
    const formData = new FormData();
    formData.append("title", title);
    formData.append("description", description);
    formData.append("img", img);

    // LHO
    formData.append("name", name);
    formData.append("address", address);
    formData.append("phone", phone);
    formData.append("email", email);
    formData.append("time", time);
    formData.append("schedule", schedule);
    formData.append("lunch", lunch);

    for (const file of files) {
      formData.append("files", file);
    }
    switch (selectedTab) {
      case "Rapoarte":
        updateOneReports(formData, id).then(() => window.location.reload());
        break;
      case "Procese Verbale":
        updateOneCECEVerbalProcedure(formData, id).then(() =>
          window.location.reload()
        );
        break;
      case "Hotarari":
        updateOneCECEDecisions(formData, id).then(() =>
          window.location.reload()
        );
        break;
      case "Organe Ierarhic Inferioare":
        updateOneLowerHierarchicalOrgans(formData, id).then(() =>
          window.location.reload()
        );
        break;
      case "Anunturi":
        updateOneCECEAnnouncements(formData, id).then(() =>
          window.location.reload()
        );
        break;
      case "Informatii":
        updateOneCECEInformation(formData, id).then(() =>
          window.location.reload()
        );
        break;
    }
  };

  const removeItem = (item) => {
    const id = item.id;
    switch (selectedTab) {
      case "Rapoarte":
        removeOneReports(id).then(() => window.location.reload());
        break;
      case "Procese Verbale":
        removeOneCECEVerbalProcedure(id).then(() => window.location.reload());
        break;
      case "Hotarari":
        removeOneCECEDecisions(id).then(() => window.location.reload());
      case "Organe Ierarhic Inferioare":
        removeOneLowerHierarchicalOrgans(id).then(() =>
          window.location.reload()
        );
        break;
      case "Anunturi":
        removeOneCECEAnnouncements(id).then(() => window.location.reload());
        break;
      case "Informatii":
        removeOneCECEInformation(id).then(() => window.location.reload());
        break;
    }
  };

  const removeItemFile = (item) => {
    const id = item.id;
    switch (selectedTab) {
      case "Rapoarte":
        removeOneReportsFile(id).then(() => window.location.reload());
        break;
      case "Procese Verbale":
        removeOneCECEVerbalProcedureFile(id).then(() =>
          window.location.reload()
        );
        break;
      case "Hotarari":
        removeOneCECEDecisionsFile(id).then(() => window.location.reload());
      case "Anunturi":
        removeOneCECEAnnouncementsFile(id).then(() => window.location.reload());
        break;
      case "Informatii":
        removeOneCECEInformationFile(id).then(() => window.location.reload());
        break;
    }
  };

  const selectFile = (e) => {
    setImg(e.target.files[0]);
  };

  const selectMultipleFiles = (e) => {
    setFiles(e.target.files);
  };

  useEffect(() => {
    fetchReports().then((data) => {
      resourcesServices.setReports(data);
    });
    fetchCECEVerbalProcedure().then((data) => {
      resourcesServices.setCECEVerbalProcedure(data);
    });
    fetchCECEDecisions().then((data) => {
      resourcesServices.setCECEDecisions(data);
    });
    fetchLowerHierarchicalOrgans().then((data) => {
      resourcesServices.setLowerHierarchicalOrgans(data);
    });
    fetchCECEInformation().then((data) => {
      resourcesServices.setCECEInformation(data);
    });
    fetchCECEAnnouncements()
      .then((data) => {
        resourcesServices.setCECEAnnouncements(data);
      })
      .finally(() => setLoading(false));
  }, []);

  if (loading) {
    return (
      <div className="d-flex align-items-center justify-content-center py-5">
        <Spinner animation={"grow"} />
      </div>
    );
  }

  return (
    <div className="d-flex flex-column bg-white r-corner-radius w-100">
      <div
        className="d-flex justify-content-between align-items-center font-2 fw-bold text-primary shadow-sm ps-3"
        style={{ height: 70 }}
      >
        <span>CECE</span>
        {selectedTab !== "Initiere Parteniriate Publice/Private" ? (
          <button
            className="btn btn-primary r-u-corner-radius"
            style={{ height: 70, width: 70 }}
            onClick={() => setShow(true)}
          >
            <i className="bi bi-plus-circle icon-2" />
          </button>
        ) : null}
      </div>
      <div className="d-flex flex-column px-2 pt-3">
        <Tabs
          defaultActiveKey="Rapoarte"
          id="uncontrolled-tab-example"
          className="d-flex justify-content-center mb-3 text-primary fw-bold"
          onSelect={(e) => setSelectedTab(e)}
        >
          <Tab eventKey="Rapoarte" title="Rapoarte">
            <div className="d-flex flex-column">
              <Accordion className="pb-4">
                {resourcesServices.reports.count > 0
                  ? resourcesServices.reports.rows.map((item, idx) => (
                      <div
                        key={idx}
                        className="shadow-sm border-0 my-2 px-4 rounded"
                      >
                        <div className="d-flex flex-column">
                          <div className="d-flex flex-xl-row flex-column align-items-center justify-content-between  p-3">
                            <div className="d-flex flex-xl-row flex column align-items-center">
                              {item.img ? (
                                <Image
                                  className="rounded"
                                  src={process.env.REACT_APP_API_URL + item.img}
                                  style={{ maxHeight: "30vh" }}
                                />
                              ) : null}
                              <div className="d-flex flex-column  ps-xl-4">
                                <a
                                  role="button"
                                  onClick={() =>
                                    navigate(REPORTS_ROUTE + "/" + item.id)
                                  }
                                  className="text-decoration-none text-primary"
                                >
                                  <Card.Title className="truncate">
                                    {item.title}
                                  </Card.Title>
                                </a>
                                {item.description ? (
                                  <div className="truncate">
                                    {parse(item.description)}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                            <div className="d-flex flex-xl-column flex-row justify-content-around py-xl-0 py-3">
                              <CustomToggle
                                eventKey={idx}
                                onClick={() => {
                                  useAccordionButton({ idx });
                                }}
                              >
                                <i className="bi bi-pencil-square icon-2 pe-2" />
                                <span>Edit</span>
                              </CustomToggle>
                              <button
                                className="btn btn-primary d-flex align-items-center mx-xl-0 mx-2"
                                onClick={() => removeItem(item)}
                              >
                                <i className="bi bi-x-circle icon-2 pe-2" />
                                <span>Remove</span>
                              </button>
                            </div>
                          </div>
                        </div>
                        <Accordion.Collapse eventKey={idx}>
                          <div className="d-flex flex-column justify-content-around px-4 py-4 border-top">
                            <div className="d-flex flex-column justify-content-around px-4 py-4">
                              <span className="text-primary  font-2 py-2 ps-1">
                                Denumire
                              </span>
                              <Form.Control
                                aria-label="Title"
                                placeholder={item.title}
                                value={title}
                                onChange={(e) => {
                                  setTitle(e.target.value);
                                }}
                              />
                              <span className="text-primary  font-2 py-2 ps-1">
                                Descriere
                              </span>
                              <Form.Control
                                aria-label="Title"
                                placeholder={item.description}
                                value={description}
                                onChange={(e) => {
                                  setDescription(e.target.value);
                                }}
                              />
                              <span className="text-primary  font-2 py-2 ps-1">
                                Fotografie
                              </span>
                              <Form.Control
                                type={"file"}
                                onChange={selectFile}
                                aria-label="PageTitle"
                                aria-describedby="basic-addon"
                              />
                              <span className="text-primary  font-2 py-2 ps-1">
                                Documente
                              </span>
                              <Form.Control
                                type={"file"}
                                onChange={selectMultipleFiles}
                                aria-label="PageTitle"
                                aria-describedby="basic-addon"
                                multiple
                              />
                              {item.reports_files.length > 0
                                ? item.reports_files.map(
                                    (sub_item, sub_idx) => (
                                      <div
                                        key={sub_idx}
                                        className="d-flex flex-row justify-content-around align-items-center text-primary text-decoration-none py-4"
                                      >
                                        <div className="d-flex align-items-center">
                                          <i
                                            className={`bi bi-filetype-${sub_item.doc_type} icon-2 text-primary rounded ms-4 px-2 text-primary`}
                                          />
                                          <a
                                            href={
                                              process.env.REACT_APP_API_URL +
                                              sub_item.doc
                                            }
                                          >
                                            <span
                                              className="text-wrap"
                                              style={{ textAlign: "justify" }}
                                            >
                                              {sub_item.doc.split(".")[0]}
                                            </span>
                                          </a>
                                        </div>
                                        <button
                                          className="btn btn-primary"
                                          onClick={() =>
                                            removeItemFile(sub_item)
                                          }
                                        >
                                          <i className="bi bi-x-circle font-2"></i>
                                        </button>
                                      </div>
                                    )
                                  )
                                : null}
                            </div>
                            <button
                              className="btn btn-primary d-flex align-items-center justify-content-center mx-xl-0 my-4"
                              onClick={() => editItem(item)}
                            >
                              <i className="bi bi-check icon-2 pe-2" />
                              <span>Confirm</span>
                            </button>
                          </div>
                        </Accordion.Collapse>
                      </div>
                    ))
                  : null}
              </Accordion>
            </div>
          </Tab>
          <Tab eventKey="Procese Verbale" title="Procese Verbale">
            <div className="d-flex flex-column">
              <Accordion className="pb-4">
                {resourcesServices.cece_verbal_procedure.count > 0
                  ? resourcesServices.cece_verbal_procedure.rows.map(
                      (item, idx) => (
                        <div
                          key={idx}
                          className="shadow-sm border-0 my-2 px-4 rounded"
                        >
                          <div className="d-flex flex-column">
                            <div className="d-flex flex-xl-row flex-column align-items-center justify-content-between  p-3">
                              <div className="d-flex flex-xl-row flex column align-items-center">
                                {item.img ? (
                                  <Image
                                    className="rounded"
                                    src={
                                      process.env.REACT_APP_API_URL + item.img
                                    }
                                    style={{ maxHeight: "30vh" }}
                                  />
                                ) : null}
                                <div className="d-flex flex-column  ps-xl-4">
                                  <a
                                    role="button"
                                    onClick={() =>
                                      navigate(
                                        CECE_VERBAL_PROCEDURE_ROUTE +
                                          "/" +
                                          item.id
                                      )
                                    }
                                    className="text-decoration-none text-primary"
                                  >
                                    <Card.Title className="truncate">
                                      {item.title}
                                    </Card.Title>
                                  </a>
                                  {item.description ? (
                                    <div className="truncate">
                                      {parse(item.description)}
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                              <div className="d-flex flex-xl-column flex-row justify-content-around py-xl-0 py-3">
                                <CustomToggle
                                  eventKey={idx}
                                  onClick={() => {
                                    useAccordionButton({ idx });
                                  }}
                                >
                                  <i className="bi bi-pencil-square icon-2 pe-2" />
                                  <span>Edit</span>
                                </CustomToggle>
                                <button
                                  className="btn btn-primary d-flex align-items-center mx-xl-0 mx-2"
                                  onClick={() => removeItem(item)}
                                >
                                  <i className="bi bi-x-circle icon-2 pe-2" />
                                  <span>Remove</span>
                                </button>
                              </div>
                            </div>
                          </div>
                          <Accordion.Collapse eventKey={idx}>
                            <div className="d-flex flex-column justify-content-around px-4 py-4 border-top">
                              <div className="d-flex flex-column justify-content-around px-4 py-4">
                                <span className="text-primary  font-2 py-2 ps-1">
                                  Denumire
                                </span>
                                <Form.Control
                                  aria-label="Title"
                                  placeholder={item.title}
                                  value={title}
                                  onChange={(e) => {
                                    setTitle(e.target.value);
                                  }}
                                />
                                <span className="text-primary  font-2 py-2 ps-1">
                                  Descriere
                                </span>
                                <Form.Control
                                  aria-label="Title"
                                  placeholder={item.description}
                                  value={description}
                                  onChange={(e) => {
                                    setDescription(e.target.value);
                                  }}
                                />
                                <span className="text-primary  font-2 py-2 ps-1">
                                  Fotografie
                                </span>
                                <Form.Control
                                  type={"file"}
                                  onChange={selectFile}
                                  aria-label="PageTitle"
                                  aria-describedby="basic-addon"
                                />
                                <span className="text-primary  font-2 py-2 ps-1">
                                  Documente
                                </span>
                                <Form.Control
                                  type={"file"}
                                  onChange={selectMultipleFiles}
                                  aria-label="PageTitle"
                                  aria-describedby="basic-addon"
                                  multiple
                                />
                                {item.cece_verbal_procedure_files.length > 0
                                  ? item.cece_verbal_procedure_files.map(
                                      (sub_item, sub_idx) => (
                                        <div
                                          key={sub_idx}
                                          className="d-flex flex-row justify-content-around align-items-center text-primary text-decoration-none py-4"
                                        >
                                          <div className="d-flex align-items-center">
                                            <i
                                              className={`bi bi-filetype-${sub_item.doc_type} icon-2 text-primary rounded ms-4 px-2 text-primary`}
                                            />
                                            <a
                                              href={
                                                process.env.REACT_APP_API_URL +
                                                sub_item.doc
                                              }
                                            >
                                              <span
                                                className="text-wrap"
                                                style={{
                                                  textAlign: "justify",
                                                }}
                                              >
                                                {sub_item.doc.split(".")[0]}
                                              </span>
                                            </a>
                                          </div>
                                          <button
                                            className="btn btn-primary"
                                            onClick={() =>
                                              removeItemFile(sub_item)
                                            }
                                          >
                                            <i className="bi bi-x-circle font-2"></i>
                                          </button>
                                        </div>
                                      )
                                    )
                                  : null}
                              </div>
                              <button
                                className="btn btn-primary d-flex align-items-center justify-content-center mx-xl-0 my-4"
                                onClick={() => editItem(item)}
                              >
                                <i className="bi bi-check icon-2 pe-2" />
                                <span>Confirm</span>
                              </button>
                            </div>
                          </Accordion.Collapse>
                        </div>
                      )
                    )
                  : null}
              </Accordion>
            </div>
          </Tab>
          <Tab eventKey="Hotarari" title="Hotarari">
            <div className="d-flex flex-column">
              <Accordion className="pb-4">
                {resourcesServices.cece_decisions.count > 0
                  ? resourcesServices.cece_decisions.rows.map((item, idx) => (
                      <div
                        key={idx}
                        className="shadow-sm border-0 my-2 px-4 rounded"
                      >
                        <div className="d-flex flex-column">
                          <div className="d-flex flex-xl-row flex-column align-items-center justify-content-between  p-3">
                            <div className="d-flex flex-xl-row flex column align-items-center">
                              {item.img ? (
                                <Image
                                  className="rounded"
                                  src={process.env.REACT_APP_API_URL + item.img}
                                  style={{ maxHeight: "30vh" }}
                                />
                              ) : null}
                              <div className="d-flex flex-column  ps-xl-4">
                                <a
                                  role="button"
                                  onClick={() =>
                                    navigate(DECISIONS_ROUTE + "/" + item.id)
                                  }
                                  className="text-decoration-none text-primary"
                                >
                                  <Card.Title className="truncate">
                                    {item.title}
                                  </Card.Title>
                                </a>
                                {item.description ? (
                                  <div className="truncate">
                                    {parse(item.description)}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                            <div className="d-flex flex-xl-column flex-row justify-content-around py-xl-0 py-3">
                              <CustomToggle
                                eventKey={idx}
                                onClick={() => {
                                  useAccordionButton({ idx });
                                }}
                              >
                                <i className="bi bi-pencil-square icon-2 pe-2" />
                                <span>Edit</span>
                              </CustomToggle>
                              <button
                                className="btn btn-primary d-flex align-items-center mx-xl-0 mx-2"
                                onClick={() => removeItem(item)}
                              >
                                <i className="bi bi-x-circle icon-2 pe-2" />
                                <span>Remove</span>
                              </button>
                            </div>
                          </div>
                        </div>
                        <Accordion.Collapse eventKey={idx}>
                          <div className="d-flex flex-column justify-content-around px-4 py-4 border-top">
                            <div className="d-flex flex-column justify-content-around px-4 py-4">
                              <span className="text-primary  font-2 py-2 ps-1">
                                Denumire
                              </span>
                              <Form.Control
                                aria-label="Title"
                                placeholder={item.title}
                                value={title}
                                onChange={(e) => {
                                  setTitle(e.target.value);
                                }}
                              />
                              <span className="text-primary  font-2 py-2 ps-1">
                                Descriere
                              </span>
                              <Form.Control
                                aria-label="Title"
                                placeholder={item.description}
                                value={description}
                                onChange={(e) => {
                                  setDescription(e.target.value);
                                }}
                              />
                              <span className="text-primary  font-2 py-2 ps-1">
                                Fotografie
                              </span>
                              <Form.Control
                                type={"file"}
                                onChange={selectFile}
                                aria-label="PageTitle"
                                aria-describedby="basic-addon"
                              />
                              <span className="text-primary  font-2 py-2 ps-1">
                                Documente
                              </span>
                              <Form.Control
                                type={"file"}
                                onChange={selectMultipleFiles}
                                aria-label="PageTitle"
                                aria-describedby="basic-addon"
                                multiple
                              />
                              {item.cece_decisions_files.length > 0
                                ? item.cece_decisions_files.map(
                                    (sub_item, sub_idx) => (
                                      <div
                                        key={sub_idx}
                                        className="d-flex flex-row justify-content-around align-items-center text-primary text-decoration-none py-4"
                                      >
                                        <div className="d-flex align-items-center">
                                          <i
                                            className={`bi bi-filetype-${sub_item.doc_type} icon-2 text-primary rounded ms-4 px-2 text-primary`}
                                          />
                                          <a
                                            href={
                                              process.env.REACT_APP_API_URL +
                                              sub_item.doc
                                            }
                                          >
                                            <span
                                              className="text-wrap"
                                              style={{ textAlign: "justify" }}
                                            >
                                              {sub_item.doc.split(".")[0]}
                                            </span>
                                          </a>
                                        </div>
                                        <button
                                          className="btn btn-primary"
                                          onClick={() =>
                                            removeItemFile(sub_item)
                                          }
                                        >
                                          <i className="bi bi-x-circle font-2"></i>
                                        </button>
                                      </div>
                                    )
                                  )
                                : null}
                            </div>
                            <button
                              className="btn btn-primary d-flex align-items-center justify-content-center mx-xl-0 my-4"
                              onClick={() => editItem(item)}
                            >
                              <i className="bi bi-check icon-2 pe-2" />
                              <span>Confirm</span>
                            </button>
                          </div>
                        </Accordion.Collapse>
                      </div>
                    ))
                  : null}
              </Accordion>
            </div>
          </Tab>
          <Tab
            eventKey="Organe Ierarhic Inferioare"
            title="Organe Ierarhic Inferioare"
          >
            <div className="d-flex flex-column">
              <Accordion className="pb-4">
                {resourcesServices.lower_hierarchical_organs.count > 0
                  ? resourcesServices.lower_hierarchical_organs.rows.map(
                      (item, idx) => (
                        <div
                          key={idx}
                          className="shadow-sm border-0 my-2 px-4 rounded"
                        >
                          <div className="d-flex flex-column">
                            <div className="d-flex flex-xl-row flex-column align-items-center justify-content-between  p-3">
                              <div className="d-flex flex-xl-row flex column align-items-center">
                                <div className="d-flex flex-column  ps-xl-4">
                                  <Card.Title className="text-primary">
                                    {item.name}
                                  </Card.Title>
                                  <div className="d-flex flex-xl-row flex-column align-items-center justify-content-around mt-3">
                                    <span className="border border-primary rounded px-2 py-1 me-xl-2">
                                      {item.address}
                                    </span>
                                    <span className="border border-primary rounded px-2 py-1 me-xl-2">
                                      {item.phone}
                                    </span>
                                    <span className="border border-primary rounded px-2 py-1 me-xl-2">
                                      {item.email}
                                    </span>
                                    <span className="border border-primary rounded px-2 py-1 me-xl-2">
                                      {item.time}
                                    </span>
                                    <span className="border border-primary rounded px-2 py-1 me-xl-2">
                                      {item.schedule}
                                    </span>
                                    <span className="border border-primary rounded px-2 py-1 me-xl-2">
                                      {item.lunch}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="d-flex flex-xl-column flex-row justify-content-around py-xl-0 py-3">
                                <CustomToggle
                                  eventKey={idx}
                                  onClick={() => {
                                    useAccordionButton({ idx });
                                  }}
                                >
                                  <i className="bi bi-pencil-square icon-2 pe-2" />
                                  <span>Edit</span>
                                </CustomToggle>
                                <button
                                  className="btn btn-primary d-flex align-items-center mx-xl-0 mx-2"
                                  onClick={() => removeItem(item)}
                                >
                                  <i className="bi bi-x-circle icon-2 pe-2" />
                                  <span>Remove</span>
                                </button>
                              </div>
                            </div>
                          </div>
                          <Accordion.Collapse eventKey={idx}>
                            <div className="d-flex flex-column justify-content-around px-4 py-4 border-top">
                              <div className="d-flex flex-column justify-content-around px-4 py-4">
                                <div className="mb-3">
                                   
                                  <span className="text-primary font-2 py-2 ps-1">
                                    Denumire
                                  </span>
                                  <Form.Control
                                    aria-label="Name"
                                    placeholder={item.name}
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                  />
                                </div>
                                <div className="mb-3">
                                  <span className="text-primary font-2 py-2 ps-1">
                                    Adresă
                                  </span>
                                  <Form.Control
                                    aria-label="Address"
                                    placeholder={item.address}
                                    value={address}
                                    onChange={(e) => setAddress(e.target.value)}
                                  />
                                </div>
                                <div className="mb-3">
                                  <span className="text-primary font-2 py-2 ps-1">
                                    Telefon
                                  </span>
                                  <Form.Control
                                    aria-label="Phone"
                                    placeholder={item.phone}
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)}
                                  />
                                </div>
                                <div className="mb-3">
                                  <span className="text-primary font-2 py-2 ps-1">
                                    Email
                                  </span>
                                  <Form.Control
                                    aria-label="Email"
                                    placeholder={item.email}
                                    type="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                  />
                                </div>
                                <div className="mb-3">
                                  <span className="text-primary font-2 py-2 ps-1">
                                    Oră
                                  </span>
                                  <Form.Control
                                    aria-label="Time"
                                    placeholder={item.time}
                                    value={time}
                                    onChange={(e) => setTime(e.target.value)}
                                  />
                                </div>
                                <div className="mb-3">
                                  <span className="text-primary font-2 py-2 ps-1">
                                    Program
                                  </span>
                                  <Form.Control
                                    aria-label="Schedule"
                                    placeholder={item.schedule}
                                    value={schedule}
                                    onChange={(e) =>
                                      setSchedule(e.target.value)
                                    }
                                  />
                                </div>
                                <div className="mb-3">
                                  <span className="text-primary font-2 py-2 ps-1">
                                    Prânz
                                  </span>
                                  <Form.Control
                                    aria-label="Lunch"
                                    placeholder={item.lunch}
                                    value={lunch}
                                    onChange={(e) => setLunch(e.target.value)}
                                  />
                                </div>
                              </div>
                              <button
                                className="btn btn-primary d-flex align-items-center justify-content-center mx-xl-0 my-4"
                                onClick={() => editItem(item)}
                              >
                                <i className="bi bi-check icon-2 pe-2" />
                                <span>Confirm</span>
                              </button>
                            </div>
                          </Accordion.Collapse>
                        </div>
                      )
                    )
                  : null}
              </Accordion>
            </div>
          </Tab>
          <Tab eventKey="Anunturi" title="Anunturi">
            <div className="d-flex flex-column">
              <Accordion className="pb-4">
                {resourcesServices.cece_announcements.count > 0
                  ? resourcesServices.cece_announcements.rows.map(
                      (item, idx) => (
                        <div
                          key={idx}
                          className="shadow-sm border-0 my-2 px-4 rounded"
                        >
                          <div className="d-flex flex-column">
                            <div className="d-flex flex-xl-row flex-column align-items-center justify-content-between  p-3">
                              <div className="d-flex flex-xl-row flex column align-items-center">
                                {item.img ? (
                                  <Image
                                    className="rounded"
                                    src={
                                      process.env.REACT_APP_API_URL + item.img
                                    }
                                    style={{ maxHeight: "30vh" }}
                                  />
                                ) : null}
                                <div className="d-flex flex-column  ps-xl-4">
                                  <a
                                    role="button"
                                    onClick={() =>
                                      navigate(
                                        ANNOUNCEMENTS_ROUTE + "/" + item.id
                                      )
                                    }
                                    className="text-decoration-none text-primary"
                                  >
                                    <Card.Title className="truncate">
                                      {item.title}
                                    </Card.Title>
                                  </a>
                                  {item.description ? (
                                    <div className="truncate">
                                      {parse(item.description)}
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                              <div className="d-flex flex-xl-column flex-row justify-content-around py-xl-0 py-3">
                                <CustomToggle
                                  eventKey={idx}
                                  onClick={() => {
                                    useAccordionButton({ idx });
                                  }}
                                >
                                  <i className="bi bi-pencil-square icon-2 pe-2" />
                                  <span>Edit</span>
                                </CustomToggle>
                                <button
                                  className="btn btn-primary d-flex align-items-center mx-xl-0 mx-2"
                                  onClick={() => removeItem(item)}
                                >
                                  <i className="bi bi-x-circle icon-2 pe-2" />
                                  <span>Remove</span>
                                </button>
                              </div>
                            </div>
                          </div>
                          <Accordion.Collapse eventKey={idx}>
                            <div className="d-flex flex-column justify-content-around px-4 py-4 border-top">
                              <div className="d-flex flex-column justify-content-around px-4 py-4">
                                <span className="text-primary  font-2 py-2 ps-1">
                                  Denumire
                                </span>
                                <Form.Control
                                  aria-label="Title"
                                  placeholder={item.title}
                                  value={title}
                                  onChange={(e) => {
                                    setTitle(e.target.value);
                                  }}
                                />
                                <span className="text-primary  font-2 py-2 ps-1">
                                  Descriere
                                </span>
                                <Form.Control
                                  aria-label="Title"
                                  placeholder={item.description}
                                  value={description}
                                  onChange={(e) => {
                                    setDescription(e.target.value);
                                  }}
                                />
                                <span className="text-primary  font-2 py-2 ps-1">
                                  Fotografie
                                </span>
                                <Form.Control
                                  type={"file"}
                                  onChange={selectFile}
                                  aria-label="PageTitle"
                                  aria-describedby="basic-addon"
                                />
                                <span className="text-primary  font-2 py-2 ps-1">
                                  Documente
                                </span>
                                <Form.Control
                                  type={"file"}
                                  onChange={selectMultipleFiles}
                                  aria-label="PageTitle"
                                  aria-describedby="basic-addon"
                                  multiple
                                />
                                {item.cece_announcements_files.length > 0
                                  ? item.cece_announcements_files.map(
                                      (sub_item, sub_idx) => (
                                        <div
                                          key={sub_idx}
                                          className="d-flex flex-row justify-content-around align-items-center text-primary text-decoration-none py-4"
                                        >
                                          <div className="d-flex align-items-center">
                                            <i
                                              className={`bi bi-filetype-${sub_item.doc_type} icon-2 text-primary rounded ms-4 px-2 text-primary`}
                                            />
                                            <a
                                              href={
                                                process.env.REACT_APP_API_URL +
                                                sub_item.doc
                                              }
                                            >
                                              <span
                                                className="text-wrap"
                                                style={{
                                                  textAlign: "justify",
                                                }}
                                              >
                                                {sub_item.doc.split(".")[0]}
                                              </span>
                                            </a>
                                          </div>
                                          <button
                                            className="btn btn-primary"
                                            onClick={() =>
                                              removeItemFile(sub_item)
                                            }
                                          >
                                            <i className="bi bi-x-circle font-2"></i>
                                          </button>
                                        </div>
                                      )
                                    )
                                  : null}
                              </div>
                              <button
                                className="btn btn-primary d-flex align-items-center justify-content-center mx-xl-0 my-4"
                                onClick={() => editItem(item)}
                              >
                                <i className="bi bi-check icon-2 pe-2" />
                                <span>Confirm</span>
                              </button>
                            </div>
                          </Accordion.Collapse>
                        </div>
                      )
                    )
                  : null}
              </Accordion>
            </div>
          </Tab>
          <Tab eventKey="Informatii" title="Informatii">
            <div className="d-flex flex-column">
              <Accordion className="pb-4">
                {resourcesServices.cece_information.count > 0
                  ? resourcesServices.cece_information.rows.map((item, idx) => (
                      <div
                        key={idx}
                        className="shadow-sm border-0 my-2 px-4 rounded"
                      >
                        <div className="d-flex flex-column">
                          <div className="d-flex flex-xl-row flex-column align-items-center justify-content-between  p-3">
                            <div className="d-flex flex-xl-row flex column align-items-center">
                              {item.img ? (
                                <Image
                                  className="rounded"
                                  src={process.env.REACT_APP_API_URL + item.img}
                                  style={{ maxHeight: "30vh" }}
                                />
                              ) : null}
                              <div className="d-flex flex-column  ps-xl-4">
                                <a
                                  role="button"
                                  onClick={() =>
                                    navigate(INFORMATION_ROUTE + "/" + item.id)
                                  }
                                  className="text-decoration-none text-primary"
                                >
                                  <Card.Title className="truncate">
                                    {item.title}
                                  </Card.Title>
                                </a>
                                {item.description ? (
                                  <div className="truncate">
                                    {parse(item.description)}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                            <div className="d-flex flex-xl-column flex-row justify-content-around py-xl-0 py-3">
                              <CustomToggle
                                eventKey={idx}
                                onClick={() => {
                                  useAccordionButton({ idx });
                                }}
                              >
                                <i className="bi bi-pencil-square icon-2 pe-2" />
                                <span>Edit</span>
                              </CustomToggle>
                              <button
                                className="btn btn-primary d-flex align-items-center mx-xl-0 mx-2"
                                onClick={() => removeItem(item)}
                              >
                                <i className="bi bi-x-circle icon-2 pe-2" />
                                <span>Remove</span>
                              </button>
                            </div>
                          </div>
                        </div>
                        <Accordion.Collapse eventKey={idx}>
                          <div className="d-flex flex-column justify-content-around px-4 py-4 border-top">
                            <div className="d-flex flex-column justify-content-around px-4 py-4">
                              <span className="text-primary  font-2 py-2 ps-1">
                                Denumire
                              </span>
                              <Form.Control
                                aria-label="Title"
                                placeholder={item.title}
                                value={title}
                                onChange={(e) => {
                                  setTitle(e.target.value);
                                }}
                              />
                              <span className="text-primary  font-2 py-2 ps-1">
                                Descriere
                              </span>
                              <Form.Control
                                aria-label="Title"
                                placeholder={item.description}
                                value={description}
                                onChange={(e) => {
                                  setDescription(e.target.value);
                                }}
                              />
                              <span className="text-primary  font-2 py-2 ps-1">
                                Fotografie
                              </span>
                              <Form.Control
                                type={"file"}
                                onChange={selectFile}
                                aria-label="PageTitle"
                                aria-describedby="basic-addon"
                              />
                              <span className="text-primary  font-2 py-2 ps-1">
                                Documente
                              </span>
                              <Form.Control
                                type={"file"}
                                onChange={selectMultipleFiles}
                                aria-label="PageTitle"
                                aria-describedby="basic-addon"
                                multiple
                              />
                              {item.cece_information_files.length > 0
                                ? item.cece_information_files.map(
                                    (sub_item, sub_idx) => (
                                      <div
                                        key={sub_idx}
                                        className="d-flex flex-row justify-content-around align-items-center text-primary text-decoration-none py-4"
                                      >
                                        <div className="d-flex align-items-center">
                                          <i
                                            className={`bi bi-filetype-${sub_item.doc_type} icon-2 text-primary rounded ms-4 px-2 text-primary`}
                                          />
                                          <a
                                            href={
                                              process.env.REACT_APP_API_URL +
                                              sub_item.doc
                                            }
                                          >
                                            <span
                                              className="text-wrap"
                                              style={{
                                                textAlign: "justify",
                                              }}
                                            >
                                              {sub_item.doc.split(".")[0]}
                                            </span>
                                          </a>
                                        </div>
                                        <button
                                          className="btn btn-primary"
                                          onClick={() =>
                                            removeItemFile(sub_item)
                                          }
                                        >
                                          <i className="bi bi-x-circle font-2"></i>
                                        </button>
                                      </div>
                                    )
                                  )
                                : null}
                            </div>
                            <button
                              className="btn btn-primary d-flex align-items-center justify-content-center mx-xl-0 my-4"
                              onClick={() => editItem(item)}
                            >
                              <i className="bi bi-check icon-2 pe-2" />
                              <span>Confirm</span>
                            </button>
                          </div>
                        </Accordion.Collapse>
                      </div>
                    ))
                  : null}
              </Accordion>
            </div>
          </Tab>
        </Tabs>
      </div>
      <AddCECEModal
        show={show}
        onHide={() => setShow(false)}
        selectedTab={selectedTab}
      />
    </div>
  );
});

export default CECEAdmin;
