import { useContext, useState, useEffect, useRef } from "react";
import Calendar from "../components/Calendar";
import { Image, Card, Button } from "react-bootstrap";
import { Context } from "..";
import { Spinner } from "react-bootstrap";
import { fetchCitizensHearingProgram } from "../http/activitiesAPI";
import ShareButton from "../components/UI/ShareButton";

const CitizensHearingProgram = () => {
	const { citizensHearingProgram } = useContext(Context);
	const [loading, setLoading] = useState(true);

	const printRef = useRef(null);
	const handlePrint = () => {
		const originalBodyContent = document.body.innerHTML;
		document.body.innerHTML = printRef.current.outerHTML;
		window.print();

		onafterprint = (e) => {
			console.log(e);

			document.body.innerHTML = originalBodyContent;
			window.location.reload();
		};
	};

	useEffect(() => {
		fetchCitizensHearingProgram(1)
			.then((data) => {
				citizensHearingProgram.setProgram(data);
			})
			.finally(() => setLoading(false));
	}, []);

	if (loading) {
		return (
			<div className='d-flex align-items-center justify-content-center py-5'>
				<Spinner animation={"grow"} />
			</div>
		);
	}
	return (
		<div>
			<div className='d-flex flex-xl-row flex-column justify-content-center px-xl-4'>
				<div className='d-flex flex-column col-xl-8 col-12  px-4'>
					<div className='py-4 block-title text-center'>
						<span className='text-primary'>Programul Audienței Cetățenilor</span>
					</div>
					<div
						className='d-flex flex-column align-items-center justify-content-center'
						ref={printRef}>
						{citizensHearingProgram.program.count > 0
							? citizensHearingProgram.program.rows.map((item, idx) => (
									<Card
										key={"chp-" + idx}
										className='my-3 shadow border-0 col-xl-8 col-12'>
										<Card.Title className='py-2 d-flex text-center text-white align-items-center justify-content-center bg-primary u-corner-radius'>
											<span>{item.role}</span>
										</Card.Title>
										<div className='d-flex flex-xl-row flex-column align-items-center'>
											{item.img ? (
												<Image
													className='rounded mb-md-2 ms-md-2'
													style={{ maxHeight: 250 }}
													src={process.env.REACT_APP_API_URL + item.img}
												/>
											) : null}
											<Card.Body className='px-3 col-xl-8 col-12'>
												<div className='d-flex flex-column'>
													<div className='d-flex flex-row align-items-center justify-content-between pb-3 bottom-shadow'>
														<i className='bi bi-geo-alt text-primary icon-2' />
														<span className='rounded text-primary px-2 py-1'>{item.location}</span>
														{/* <Button className="btn btn-primary px-4">
                          <span>Map</span>
                        </Button> */}
													</div>

													<div className='d-flex flex-row align-items-center justify-content-between'>
														<i className='bi bi-person-vcard text-primary icon-2' />
														<span className='text-primary fw-bold px-2 py-1'>{item.full_name}</span>
													</div>

													{/* {item.phone.map((sub_item, sub_idx) => (
                      
                    ))}

                    {item.email.map((sub_item, sub_idx) => (
						))} */}
													<div className='d-flex flex-row align-items-center justify-content-between '>
														<i className='bi bi-envelope text-primary icon-2' />
														<span className='text-primary px-2 py-1'>{item.email}</span>
													</div>

													<div className='d-flex flex-row align-items-center justify-content-between bottom-shadow pb-2'>
														<i className='bi bi-telephone text-primary icon-2' />
														<span className='text-primary px-2 py-1'>{item.phone}</span>
													</div>

													<div className='d-flex flex-row align-items-center justify-content-between pt-3'>
														<i className='bi bi-clock text-primary icon-2' />
														<span className='border border-primary rounded text-primary px-2 py-1'>
															{item.days}
														</span>
														<span className='border border-primary rounded text-primary px-2 py-1'>
															{item.time}
														</span>
													</div>
												</div>
												{/* <Card.Text>{item.title}</Card.Text> */}
											</Card.Body>
										</div>
									</Card>
							  ))
							: null}
					</div>
					<div className='d-flex flex-xl-row flex-column justify-content-end align-items-center w-100'>
						<div className='d-flex flex-row align-items-center justify-content-end ps-1 pe-5'>
							<i
								role='button'
								onClick={() => handlePrint()}
								className='bi bi-printer text-primary icon-3'
								style={{
									fontWeight: "600 !important",
									WebkitTextStroke: 1,
								}}
							/>
							<ShareButton />
						</div>
					</div>
				</div>
				<Calendar />
			</div>
		</div>
	);
};

export default CitizensHearingProgram;
