import { useContext, useState, useEffect } from "react";
import { Spinner, Card, Accordion, Form, useAccordionButton, Tabs, Tab, Image } from "react-bootstrap";
import "bootstrap-icons/font/bootstrap-icons.css";
import { observer } from "mobx-react-lite";
import { Context } from "../../index";
import {
	fetchCityNews,
	fetchCommitteeMeetings,
	fetchMayorOnline,
	fetchOnlineMeetings,
	fetchOther,
	fetchPhotoGallery,
	removeOneCityNews,
	removeOneCityNewsFile,
	removeOneCommitteeMeetings,
	removeOneMayorOnline,
	removeOneOther,
	removeOnePhotoGallery,
	removeOnePhotoGalleryFile,
	updateOneCityNews,
	updateOneCommitteeMeetings,
	updateOneMayorOnline,
	updateOneOnlineMeetings,
	updateOneOther,
	updateOnePhotoGallery,
} from "../../http/NewsAPI";
import parse from "html-react-parser";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { CITY_NEWS_ROUTE, COMMITTEE_MEETINGS_ROUTE, OTHER_ROUTE } from "../../utils/consts";
import AddCityNewsModal from "./Modals/AddCityNewsModal";

const CityNewsAdmin = observer(() => {
	const { cityNews } = useContext(Context);
	const [show, setShow] = useState(false);

	const [title, setTitle] = useState("");
	const [img, setImg] = useState([null]);
	const [article, setArticle] = useState("");
	const [author, setAuthor] = useState("");
	const [link, setLink] = useState("");

	const [selectedTab, setSelectedTab] = useState("Noutăți");

	const [loading, setLoading] = useState(true);
	useEffect(() => {
		fetchCityNews().then((data) => {
			cityNews.setCityNews(data);
		});
		fetchCommitteeMeetings().then((data) => {
			cityNews.setCommitteeMeetings(data);
		});
		fetchPhotoGallery().then((data) => {
			cityNews.setPhotoGallery(data);
		});
		fetchOnlineMeetings().then((data) => {
			cityNews.setOnlineMeetings(data);
		});
		fetchMayorOnline().then((data) => {
			cityNews.setMayorOnline(data);
		});
		fetchOther()
			.then((data) => {
				cityNews.setOther(data);
			})
			.finally(() => setLoading(false));
	}, []);

	const CustomToggle = ({ children, eventKey }) => {
		const decoratedOnClick = useAccordionButton(eventKey);

		return (
			<button
				className='btn btn-primary d-flex align-items-center mb-xl-1 mx-xl-0 mx-2'
				onClick={decoratedOnClick}>
				{children}
			</button>
		);
	};

	const modules = {
		toolbar: [
			[{ size: ["small", false, "large", "huge"] }],
			["bold", "italic", "underline", "strike", "blockquote"],
			[{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }, { align: [] }],
			["link", "image"],
			[
				{
					color: [
						"#000000",
						"#e60000",
						"#ff9900",
						"#ffff00",
						"#008a00",
						"#0066cc",
						"#9933ff",
						"#ffffff",
						"#facccc",
						"#ffebcc",
						"#ffffcc",
						"#cce8cc",
						"#cce0f5",
						"#ebd6ff",
						"#bbbbbb",
						"#f06666",
						"#ffc266",
						"#ffff66",
						"#66b966",
						"#66a3e0",
						"#c285ff",
						"#888888",
						"#a10000",
						"#b26b00",
						"#b2b200",
						"#006100",
						"#0047b2",
						"#6b24b2",
						"#444444",
						"#5c0000",
						"#663d00",
						"#666600",
						"#003700",
						"#002966",
						"#3d1466",
						"custom-color",
					],
				},
			],
		],
	};

	const formats = [
		"header",
		"height",
		"bold",
		"italic",
		"underline",
		"strike",
		"blockquote",
		"list",
		"color",
		"bullet",
		"indent",
		"link",
		"image",
		"align",
		"size",
	];

	const removeItem = (item) => {
		switch (selectedTab) {
			case "Noutăți":
				removeOneCityNews(item.id).then(() => window.location.reload());
				break;
			case "Ședințe comisii":
				removeOneCommitteeMeetings(item.id).then(() => window.location.reload());
				break;
			case "Fotografii":
				removeOnePhotoGallery(item.id).then(() => window.location.reload());
				break;
			case "Ședințe online":
				removeOneCommitteeMeetings(item.id).then(() => window.location.reload());
				break;
			case "Primarul online":
				removeOneMayorOnline(item.id).then(() => window.location.reload());
				break;
			case "Alte":
				removeOneOther(item.id).then(() => window.location.reload());
				break;
		}
	};

	const removeOneItemFile = (item) => {
		switch (selectedTab) {
			case "Noutăți":
				removeOneCityNewsFile(item.id).then(() => window.location.reload());
				break;
			case "Fotografii":
				removeOnePhotoGalleryFile(item.id).then(() => window.location.reload());
				break;
		}
	};

	const editItem = (data) => {
		const id = data.id;
		const formData = new FormData();
		formData.append("title", title);
		formData.append("img", img);
		formData.append("article", article);
		formData.append("author", author);
		formData.append("link", link);
		switch (selectedTab) {
			case "Noutăți":
				updateOneCityNews(formData, id).then(() => window.location.reload());
				break;
			case "Ședințe comisii":
				updateOneCommitteeMeetings(formData, id).then(() => window.location.reload());
				break;
			case "Fotografii":
				updateOnePhotoGallery(formData, id).then(() => window.location.reload());
				break;
			case "Ședințe online":
				updateOneOnlineMeetings(formData, id).then(() => window.location.reload());
				break;
			case "Primarul online":
				updateOneMayorOnline(formData, id).then(() => window.location.reload());
				break;
			case "Alte":
				updateOneOther(formData, id).then(() => window.location.reload());
				break;
		}
	};

	if (loading) {
		return (
			<div className='d-flex align-items-center justify-content-center py-5'>
				<Spinner animation={"grow"} />
			</div>
		);
	}
	return (
		<div className='d-flex flex-column bg-white r-corner-radius w-100'>
			<div className='d-flex justify-content-between align-items-center font-2 fw-bold text-primary shadow-sm ps-3'>
				<span>Noutațile orașului</span>
				<button
					className='btn btn-primary r-u-corner-radius'
					style={{ height: 70, width: 70 }}
					onClick={() => setShow(true)}>
					<i className='bi bi-plus-circle icon-2' />
				</button>
			</div>
			<div
				className='px-5 mt-4'
				style={{ maxHeight: 1080, overflow: "auto" }}>
				<div className='d-flex flex-column px-2 pt-3'>
					<Tabs
						defaultActiveKey='Noutăți'
						id='uncontrolled-tab-example'
						className='d-flex justify-content-center mb-3 text-primary fw-bold'
						onSelect={(e) => setSelectedTab(e)}>
						<Tab
							eventKey='Noutăți'
							title='Noutăți'>
							<Accordion className='pb-4'>
								{cityNews.cityNews.count > 0
									? cityNews.cityNews.rows.map((item, idx) => (
											<div
												key={idx}
												className='shadow-sm border-0 my-2 px-4'>
												<Card.Body className='d-flex flex-column'>
													<div className='d-flex flex-xl-row flex-column align-items-center justify-content-between'>
														<div>
															<a
																href={CITY_NEWS_ROUTE + "/" + item.id}
																className='text-decoration-none text-dark'>
																<Card.Title className='text-primary truncate'>{item.title}</Card.Title>
															</a>
															<Card.Text className='truncate'>{parse(item.article)}</Card.Text>
															<div className='text-center'>
																<span className='border border-primary rounded text-primary p-1'>
																	{item.date}
																</span>
															</div>
														</div>
														<div className='d-flex flex-xl-column flex-row justify-content-around py-xl-0 py-3'>
															<CustomToggle
																eventKey={idx}
																onClick={() => {
																	useAccordionButton({ idx });
																}}>
																<i className='bi bi-pencil-square icon-2 pe-2' />
																<span>Edit</span>
															</CustomToggle>
															<button
																className='btn btn-primary d-flex align-items-center mx-xl-0 mx-2'
																onClick={() => removeItem(item)}>
																<i className='bi bi-x-circle icon-2 pe-2' />
																<span>Remove</span>
															</button>
														</div>
													</div>
												</Card.Body>
												<Accordion.Collapse eventKey={idx}>
													<div className='d-flex flex-column justify-content-around px-4 py-4 border-top'>
														<span className='text-primary  font-2 py-2 ps-1'>Denumire</span>
														<Form.Control
															aria-label='Title'
															placeholder={item.title}
															value={title}
															onChange={(e) => {
																setTitle(e.target.value);
															}}
															required
														/>
														<span className='text-primary  font-2 py-2 ps-1'>Descriere</span>
														<ReactQuill
															theme='snow'
															value={article}
															placeholder={item.article}
															modules={modules}
															formats={formats}
															onChange={setArticle}
														/>
														{item.city_news_files.length > 0 ? (
															<>
																<span className='text-primary  font-2 py-2 ps-1'>Fotografii</span>
																{item.city_news_files.map((sub_item, sub_idx) => (
																	<div
																		className='d-flex flex-row align-items-center justify-content-around border-bottom py-3'
																		key={sub_idx}>
																		<a href={process.env.REACT_APP_API_URL + "/" + sub_item.img}>
																			<Image src={process.env.REACT_APP_API_URL + sub_item.img} />
																		</a>
																		<button
																			className='btn btn-primary'
																			onClick={() => removeOneItemFile(sub_item)}>
																			<i className='bi bi-x-circle font-2'></i>
																		</button>
																	</div>
																))}
															</>
														) : null}
														<button
															className='btn btn-primary d-flex align-items-center justify-content-center mx-xl-0 my-4'
															onClick={() => editItem(item)}>
															<i className='bi bi-check icon-2 pe-2' />
															<span>Confirm</span>
														</button>
													</div>
												</Accordion.Collapse>
											</div>
									  ))
									: null}
							</Accordion>
						</Tab>
						<Tab
							eventKey='Ședințe comisii'
							title='Ședințe comisii'>
							<Accordion className='pb-4'>
								{cityNews.committeeMeetings.count > 0
									? cityNews.committeeMeetings.rows.map((item, idx) => (
											<div
												key={idx}
												className='shadow-sm border-0 my-2 px-4'>
												<Card.Body className='d-flex flex-column'>
													<div className='d-flex flex-xl-row flex-column align-items-center justify-content-between'>
														<div>
															<a
																href={COMMITTEE_MEETINGS_ROUTE + "/" + item.id}
																className='text-decoration-none text-dark'>
																<Card.Title className='text-primary truncate'>{item.title}</Card.Title>
															</a>
															<Card.Text className='truncate'>{parse(item.article)}</Card.Text>
															<div className='text-center'>
																<span className='border border-primary rounded text-primary p-1'>
																	{item.date}
																</span>
															</div>
														</div>
														<div className='d-flex flex-xl-column flex-row justify-content-around py-xl-0 py-3'>
															<CustomToggle
																eventKey={idx}
																onClick={() => {
																	useAccordionButton({ idx });
																}}>
																<i className='bi bi-pencil-square icon-2 pe-2' />
																<span>Edit</span>
															</CustomToggle>
															<button
																className='btn btn-primary d-flex align-items-center mx-xl-0 mx-2'
																onClick={() => removeItem(item)}>
																<i className='bi bi-x-circle icon-2 pe-2' />
																<span>Remove</span>
															</button>
														</div>
													</div>
												</Card.Body>
												<Accordion.Collapse eventKey={idx}>
													<div className='d-flex flex-column justify-content-around px-4 py-4 border-top'>
														<span className='text-primary  font-2 py-2 ps-1'>Denumire</span>
														<Form.Control
															aria-label='Title'
															placeholder={item.title}
															value={title}
															onChange={(e) => {
																setTitle(e.target.value);
															}}
															required
														/>
														<span className='text-primary  font-2 py-2 ps-1'>Descriere</span>
														<ReactQuill
															theme='snow'
															value={article}
															placeholder={item.article}
															modules={modules}
															formats={formats}
															onChange={setArticle}
														/>
														<button
															className='btn btn-primary d-flex align-items-center justify-content-center mx-xl-0 my-4'
															onClick={() => editItem(item)}>
															<i className='bi bi-check icon-2 pe-2' />
															<span>Confirm</span>
														</button>
													</div>
												</Accordion.Collapse>
											</div>
									  ))
									: null}
							</Accordion>
						</Tab>
						<Tab
							eventKey='Fotografii'
							title='Fotografii'>
							<Accordion className='pb-4'>
								{cityNews.photoGallery.count > 0
									? cityNews.photoGallery.rows.map((item, idx) => (
											<div
												key={idx}
												className='shadow-sm border-0 my-2 px-4'>
												<Card.Body className='d-flex flex-column'>
													<div className='d-flex flex-xl-row flex-column align-items-center justify-content-between'>
														<div>
															<a
																href={OTHER_ROUTE + "/" + item.id}
																className='text-decoration-none text-dark'>
																<Card.Title className='text-primary truncate'>{item.title}</Card.Title>
															</a>
															<Card.Text className='truncate'>{parse(item.article)}</Card.Text>
															<div className='text-center'>
																<span className='border border-primary rounded text-primary p-1'>
																	{item.date}
																</span>
															</div>
														</div>
														<div className='d-flex flex-xl-column flex-row justify-content-around py-xl-0 py-3'>
															<CustomToggle
																eventKey={idx}
																onClick={() => {
																	useAccordionButton({ idx });
																}}>
																<i className='bi bi-pencil-square icon-2 pe-2' />
																<span>Edit</span>
															</CustomToggle>
															<button
																className='btn btn-primary d-flex align-items-center mx-xl-0 mx-2'
																onClick={() => removeItem(item)}>
																<i className='bi bi-x-circle icon-2 pe-2' />
																<span>Remove</span>
															</button>
														</div>
													</div>
												</Card.Body>
												<Accordion.Collapse eventKey={idx}>
													<div className='d-flex flex-column justify-content-around px-4 py-4 border-top'>
														<span className='text-primary  font-2 py-2 ps-1'>Denumire</span>
														<Form.Control
															aria-label='Title'
															placeholder={item.title}
															value={title}
															onChange={(e) => {
																setTitle(e.target.value);
															}}
															required
														/>
														<span className='text-primary  font-2 py-2 ps-1'>Autor</span>
														<Form.Control
															aria-label='Author'
															placeholder={item.author}
															value={author}
															onChange={(e) => {
																setAuthor(e.target.value);
															}}
														/>
														{item.photo_gallery_files.length > 0 ? (
															<>
																<span className='text-primary  font-2 py-2 ps-1'>Fotografii</span>
																{item.photo_gallery_files.map((sub_item, sub_idx) => (
																	<div
																		className='d-flex flex-row align-items-center justify-content-around border-bottom py-3'
																		key={sub_idx}>
																		<a href={process.env.REACT_APP_API_URL + "/" + sub_item.img}>
																			<Image src={process.env.REACT_APP_API_URL + sub_item.img} />
																		</a>
																		<button
																			className='btn btn-primary'
																			onClick={() => removeOneItemFile(sub_item)}>
																			<i className='bi bi-x-circle font-2'></i>
																		</button>
																	</div>
																))}
															</>
														) : null}
														<span className='text-primary  font-2 py-2 ps-1'>Descriere</span>
														<ReactQuill
															theme='snow'
															value={article}
															//   placeholder={parse(item.article)}
															modules={modules}
															formats={formats}
															onChange={setArticle}
														/>
														<button
															className='btn btn-primary d-flex align-items-center justify-content-center mx-xl-0 my-4'
															onClick={() => editItem(item)}>
															<i className='bi bi-check icon-2 pe-2' />
															<span>Confirm</span>
														</button>
													</div>
												</Accordion.Collapse>
											</div>
									  ))
									: null}
							</Accordion>
						</Tab>
						<Tab
							eventKey='Ședințe online'
							title='Ședințe online'>
							<Accordion className='pb-4'>
								{cityNews.onlineMeetings.count > 0
									? cityNews.onlineMeetings.rows.map((item, idx) => (
											<div
												key={idx}
												className='shadow-sm border-0 my-2 px-4'>
												<Card.Body className='d-flex flex-column'>
													<div className='d-flex flex-xl-row flex-column align-items-center justify-content-between'>
														<div>
															<a
																href={OTHER_ROUTE + "/" + item.id}
																className='text-decoration-none text-dark'>
																<Card.Title className='text-primary truncate'>{item.title}</Card.Title>
															</a>
															<Card.Text className='truncate'>{parse(item.article)}</Card.Text>
															<div className='text-center'>
																<span className='border border-primary rounded text-primary p-1'>
																	{item.date}
																</span>
															</div>
														</div>
														<div className='d-flex flex-xl-column flex-row justify-content-around py-xl-0 py-3'>
															<CustomToggle
																eventKey={idx}
																onClick={() => {
																	useAccordionButton({ idx });
																}}>
																<i className='bi bi-pencil-square icon-2 pe-2' />
																<span>Edit</span>
															</CustomToggle>
															<button
																className='btn btn-primary d-flex align-items-center mx-xl-0 mx-2'
																onClick={() => removeItem(item)}>
																<i className='bi bi-x-circle icon-2 pe-2' />
																<span>Remove</span>
															</button>
														</div>
													</div>
												</Card.Body>
												<Accordion.Collapse eventKey={idx}>
													<div className='d-flex flex-column justify-content-around px-4 py-4 border-top'>
														<span className='text-primary  font-2 py-2 ps-1'>Denumire</span>
														<Form.Control
															aria-label='Title'
															placeholder={item.title}
															value={title}
															onChange={(e) => {
																setTitle(e.target.value);
															}}
															required
														/>
														<span className='text-primary  font-2 py-2 ps-1'>Descriere</span>
														<ReactQuill
															theme='snow'
															value={article}
															modules={modules}
															formats={formats}
															onChange={setArticle}
														/>
														<span className='text-primary  font-2 py-2 ps-1'>Lincul catre resurs</span>
														<Form.Control
															aria-label='Link'
															placeholder={item.link}
															value={link}
															onChange={(e) => {
																setLink(e.target.value);
															}}
														/>
														<span className='text-primary  font-2 py-2 ps-1'>Autor</span>
														<Form.Control
															aria-label='Author'
															placeholder={item.author}
															value={author}
															onChange={(e) => {
																setAuthor(e.target.value);
															}}
														/>
														<button
															className='btn btn-primary d-flex align-items-center justify-content-center mx-xl-0 my-4'
															onClick={() => editItem(item)}>
															<i className='bi bi-check icon-2 pe-2' />
															<span>Confirm</span>
														</button>
													</div>
												</Accordion.Collapse>
											</div>
									  ))
									: null}
							</Accordion>
						</Tab>
						<Tab
							eventKey='Primarul online'
							title='Primarul online'>
							<Accordion className='pb-4'>
								{cityNews.mayorOnline.count > 0
									? cityNews.mayorOnline.rows.map((item, idx) => (
											<div
												key={idx}
												className='shadow-sm border-0 my-2 px-4'>
												<Card.Body className='d-flex flex-column'>
													<div className='d-flex flex-xl-row flex-column align-items-center justify-content-between'>
														<div>
															<a
																href={OTHER_ROUTE + "/" + item.id}
																className='text-decoration-none text-dark'>
																<Card.Title className='text-primary truncate'>{item.title}</Card.Title>
															</a>
															<Card.Text className='truncate'>{parse(item.article)}</Card.Text>
															<div className='text-center'>
																<span className='border border-primary rounded text-primary p-1'>
																	{item.date}
																</span>
															</div>
														</div>
														<div className='d-flex flex-xl-column flex-row justify-content-around py-xl-0 py-3'>
															<CustomToggle
																eventKey={idx}
																onClick={() => {
																	useAccordionButton({ idx });
																}}>
																<i className='bi bi-pencil-square icon-2 pe-2' />
																<span>Edit</span>
															</CustomToggle>
															<button
																className='btn btn-primary d-flex align-items-center mx-xl-0 mx-2'
																onClick={() => removeItem(item)}>
																<i className='bi bi-x-circle icon-2 pe-2' />
																<span>Remove</span>
															</button>
														</div>
													</div>
												</Card.Body>
												<Accordion.Collapse eventKey={idx}>
													<div className='d-flex flex-column justify-content-around px-4 py-4 border-top'>
														<span className='text-primary  font-2 py-2 ps-1'>Denumire</span>
														<Form.Control
															aria-label='Title'
															placeholder={item.title}
															value={title}
															onChange={(e) => {
																setTitle(e.target.value);
															}}
															required
														/>
														<span className='text-primary  font-2 py-2 ps-1'>Descriere</span>
														<ReactQuill
															theme='snow'
															value={article}
															modules={modules}
															formats={formats}
															onChange={setArticle}
														/>
														<span className='text-primary  font-2 py-2 ps-1'>Lincul catre resurs</span>
														<Form.Control
															aria-label='Link'
															placeholder={item.link}
															value={link}
															onChange={(e) => {
																setLink(e.target.value);
															}}
														/>
														<span className='text-primary  font-2 py-2 ps-1'>Autor</span>
														<Form.Control
															aria-label='Author'
															placeholder={item.author}
															value={author}
															onChange={(e) => {
																setAuthor(e.target.value);
															}}
														/>
														<button
															className='btn btn-primary d-flex align-items-center justify-content-center mx-xl-0 my-4'
															onClick={() => editItem(item)}>
															<i className='bi bi-check icon-2 pe-2' />
															<span>Confirm</span>
														</button>
													</div>
												</Accordion.Collapse>
											</div>
									  ))
									: null}
							</Accordion>
						</Tab>
						<Tab
							eventKey='Alte'
							title='Alte'>
							<Accordion className='pb-4'>
								{cityNews.other.count > 0
									? cityNews.other.rows.map((item, idx) => (
											<div
												key={idx}
												className='shadow-sm border-0 my-2 px-4'>
												<Card.Body className='d-flex flex-column'>
													<div className='d-flex flex-xl-row flex-column align-items-center justify-content-between'>
														<div>
															<a
																href={OTHER_ROUTE + "/" + item.id}
																className='text-decoration-none text-dark'>
																<Card.Title className='text-primary truncate'>{item.title}</Card.Title>
															</a>
															<Card.Text className='truncate'>{parse(item.article)}</Card.Text>
															<div className='text-center'>
																<span className='border border-primary rounded text-primary p-1'>
																	{item.date}
																</span>
															</div>
														</div>
														<div className='d-flex flex-xl-column flex-row justify-content-around py-xl-0 py-3'>
															<CustomToggle
																eventKey={idx}
																onClick={() => {
																	useAccordionButton({ idx });
																}}>
																<i className='bi bi-pencil-square icon-2 pe-2' />
																<span>Edit</span>
															</CustomToggle>
															<button
																className='btn btn-primary d-flex align-items-center mx-xl-0 mx-2'
																onClick={() => removeItem(item)}>
																<i className='bi bi-x-circle icon-2 pe-2' />
																<span>Remove</span>
															</button>
														</div>
													</div>
												</Card.Body>
												<Accordion.Collapse eventKey={idx}>
													<div className='d-flex flex-column justify-content-around px-4 py-4 border-top'>
														<span className='text-primary  font-2 py-2 ps-1'>Denumire</span>
														<Form.Control
															aria-label='Title'
															placeholder={item.title}
															value={title}
															onChange={(e) => {
																setTitle(e.target.value);
															}}
															required
														/>
														<span className='text-primary  font-2 py-2 ps-1'>Descriere</span>
														<ReactQuill
															theme='snow'
															value={article}
															modules={modules}
															formats={formats}
															onChange={setArticle}
														/>
														<span className='text-primary  font-2 py-2 ps-1'>Lincul catre resurs</span>
														<Form.Control
															aria-label='Link'
															placeholder={item.link}
															value={link}
															onChange={(e) => {
																setLink(e.target.value);
															}}
														/>
														<span className='text-primary  font-2 py-2 ps-1'>Autor</span>
														<Form.Control
															aria-label='Author'
															placeholder={item.author}
															value={author}
															onChange={(e) => {
																setAuthor(e.target.value);
															}}
														/>
														<button
															className='btn btn-primary d-flex align-items-center justify-content-center mx-xl-0 my-4'
															onClick={() => editItem(item)}>
															<i className='bi bi-check icon-2 pe-2' />
															<span>Confirm</span>
														</button>
													</div>
												</Accordion.Collapse>
											</div>
									  ))
									: null}
							</Accordion>
						</Tab>
					</Tabs>
				</div>
			</div>
			<AddCityNewsModal
				show={show}
				onHide={() => setShow(false)}
				selectedTab={selectedTab}
			/>
		</div>
	);
});

export default CityNewsAdmin;
