import { useContext, useState, useEffect, useRef } from "react";
import Calendar from "../components/Calendar";
import { Context } from "..";
import { Spinner } from "react-bootstrap";
import { fetchLocationInNumbers } from "../http/resourcesServicesAPI";
import ShareButton from "../components/UI/ShareButton";

const LocationInNumbers = () => {
	const { resourcesServices } = useContext(Context);
	const [loading, setLoading] = useState(true);

	const printRef = useRef(null);
	const handlePrint = () => {
		const originalBodyContent = document.body.innerHTML;
		document.body.innerHTML = printRef.current.outerHTML;
		window.print();

		onafterprint = (e) => {
			console.log(e);

			document.body.innerHTML = originalBodyContent;
		};
		window.location.reload();
	};

	useEffect(() => {
		fetchLocationInNumbers()
			.then((data) => {
				resourcesServices.setLocationInNumbers(data);
			})
			.finally(() => setLoading(false));
	}, []);
	if (loading) {
		return (
			<div className='d-flex align-items-center justify-content-center py-5'>
				<Spinner animation={"grow"} />
			</div>
		);
	}
	return (
		<div>
			<div className='d-flex flex-xl-row flex-column justify-content-center px-xl-4 px-2'>
				<div
					className='d-flex flex-column col-xl-8 px-xl-4'
					ref={printRef}>
					<div className='py-4 block-title text-center'>
						<span className='text-primary'>Edineț in Cifre</span>
					</div>

					<div className='d-flex flex-xl-row flex-wrap flex-column align-items-center justify-content-center shadow-sm rounded p-2 mb-4'>
						{resourcesServices.locationInNumbers.count > 0
							? resourcesServices.locationInNumbers.rows.map((item, idx) => (
									<div className='d-flex flex-column align-items-center justify-content-center col-xl-3 px-5 my-4 text-center'>
										<i className={`bi ${item.icon}  text-primary icon-4 px-3`} />
										<span className='text-primary font-2 fw-bold'>{item.value}</span>
										<span className='fw-bold'>{item.title}</span>
									</div>
							  ))
							: null}
					</div>
					<div className='d-flex flex-xl-row flex-column align-items-center justify-content-end w-100'>
						<i
							role='button'
							onClick={() => handlePrint()}
							className='bi bi-printer text-primary icon-3 px-4'
							style={{
								fontWeight: "600 !important",
								WebkitTextStroke: 1,
							}}
						/>
						<ShareButton />
					</div>
				</div>
				<Calendar />
			</div>
		</div>
	);
};

export default LocationInNumbers;
