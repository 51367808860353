import React from "react";
import { ListGroup, Tab } from "react-bootstrap";
import "bootstrap-icons/font/bootstrap-icons.css";
import { observer } from "mobx-react-lite";
import LastNewsAdmin from "./LastNewsAdmin";
import CityNewsAdmin from "./CityNewsAdmin";
import CalendarAdmin from "./CalendarAdmin";
import NavBarFooterAdmin from "./NavBarFooterAdmin";
import ReferencesAdmin from "./ReferencesAdmin";
import UsefulAdmin from "./UsefulAdmin";
import MainBannerAdmin from "./MainBannerAdmin";
import PublicInformationAdmin from "./PublicInformationAdmin";
import DecisionProcessAdmin from "./DecisionProcessAdmin";
import ServiceListAdmin from "./ServiceListAdmin";
import CECEAdmin from "./CECEAdmin";
import InvestmentsAdmin from "./InvestmentsAdmin";
import VisitDistrictAdmin from "./VisitDistrictAdmin";
import ContactsAdmin from "./ContactsAdmin";
import PublicNoticesAdmin from "./PublicNoticesAdmin";
import EconomyAdmin from "./EconomyAdmin";
import CommunityAdmin from "./CommunityAdmin";
import AdministrationAdmin from "./AdministrationAdmin";
import ExtraordinaryCommissionsAdmin from "./ExtraordinaryCommissionsAdmin";
import CitizensHearingProgramAdmin from "./CitizensHearingProgramAdmin";
import ProjectsAdmin from "./ProjectsAdmin";

const Admin = observer(() => {
    return (
        <div>
            {}
            <div className="d-flex rounded flex-row justify-content-between my-4 mx-5 shadow">
                <Tab.Container id="list-group-tabs-example" defaultActiveKey="#link1">
                    <div className="d-flex flex-column">
                        <div
                            className="font-2 text-white bg-primary px-3 admin-panel-block-list l-u-corner-radius py-3"
                            style={{ minWidth: 280, minHeight: 70 }}
                        >
                            Panel de Administrare
                        </div>
                        <ListGroup defaultActiveKey="#nav_menu" variant="flush">
                            <ListGroup.Item action className="px-3 py-3" eventKey="#link1">
                                Navigare & Footer
                            </ListGroup.Item>
                            <ListGroup.Item action className="px-3 py-3" eventKey="#link2">
                                Banner
                            </ListGroup.Item>
                            <ListGroup.Item action className="px-3 py-3" eventKey="#link3">
                                Calendar
                            </ListGroup.Item>
                            <ListGroup.Item action className="px-3 py-3" eventKey="#link4">
                                Știri de Ultimă Oră
                            </ListGroup.Item>
                            <ListGroup.Item action className="px-3 py-3" eventKey="#link5">
                                Lista cu servicii
                            </ListGroup.Item>
                            <ListGroup.Item action className="px-3 py-3" eventKey="#link6">
                                Investeste in r-ul Edinet
                            </ListGroup.Item>
                            <ListGroup.Item action className="px-3 py-3" eventKey="#link7">
                                Vizitează r-ul Edinet
                            </ListGroup.Item>
                            <ListGroup.Item action className="px-3 py-3" eventKey="#link8">
                                CECE Nr. 16
                            </ListGroup.Item>
                            <ListGroup.Item action className="px-3 py-3" eventKey="#link9">
                                Noutațile orașului
                            </ListGroup.Item>
                            <ListGroup.Item action className="px-3 py-3" eventKey="#link10">
                                Informații publice
                            </ListGroup.Item>
                            <ListGroup.Item action className="px-3 py-3" eventKey="#link11">
                                Procesul decizional
                            </ListGroup.Item>
                            <ListGroup.Item action className="px-3 py-3" eventKey="#link12">
                                Lincuri utile
                            </ListGroup.Item>
                            <ListGroup.Item action className="px-3 py-3" eventKey="#link13">
                                Referițe
                            </ListGroup.Item>
                            <ListGroup.Item action className="px-3 py-3" eventKey="#link14">
                                Contacte
                            </ListGroup.Item>
                            <ListGroup.Item action className="px-3 py-3" eventKey="#link15">
                                Anunțuri de interes public
                            </ListGroup.Item>
                            <ListGroup.Item action className="px-3 py-3" eventKey="#link16">
                                Economie
                            </ListGroup.Item>
                            <ListGroup.Item action className="px-3 py-3" eventKey="#link17">
                                Comunitate
                            </ListGroup.Item>
                            <ListGroup.Item action className="px-3 py-3" eventKey="#link18">
                                Administrație
                            </ListGroup.Item>
                            <ListGroup.Item action className="px-3 py-3" eventKey="#link19">
                                Comisii Extraordinare
                            </ListGroup.Item>
                            <ListGroup.Item action className="px-3 py-3" eventKey="#link20">
                                Programul Audienței Cetățenilor
                            </ListGroup.Item>
                            <ListGroup.Item action className="px-3 py-3" eventKey="#link21">
                                Proiecte
                            </ListGroup.Item>
                            <ListGroup.Item action className="px-3 py-3" eventKey="#link22">
                                Ajutor
                            </ListGroup.Item>
                            <ListGroup.Item action className="px-3 py-4 text-center l-d-corner-radius">
                                <span className="fw-bold text-primary font-2">WEB APL 3.12.1</span>
                            </ListGroup.Item>
                        </ListGroup>
                    </div>
                    <Tab.Content className="w-100">
                        <Tab.Pane eventKey="#link1">
                            <NavBarFooterAdmin />
                        </Tab.Pane>
                        <Tab.Pane eventKey="#link2">
                            <MainBannerAdmin />
                        </Tab.Pane>
                        <Tab.Pane eventKey="#link3">
                            <CalendarAdmin />
                        </Tab.Pane>
                        <Tab.Pane eventKey="#link4">
                            <LastNewsAdmin />
                        </Tab.Pane>
                        <Tab.Pane eventKey="#link5">
                            <ServiceListAdmin />
                        </Tab.Pane>
                        <Tab.Pane eventKey="#link6">
                            <InvestmentsAdmin />
                        </Tab.Pane>
                        <Tab.Pane eventKey="#link7">
                            <VisitDistrictAdmin />
                        </Tab.Pane>
                        <Tab.Pane eventKey="#link8">
                            <CECEAdmin />
                        </Tab.Pane>
                        <Tab.Pane eventKey="#link9">
                            <CityNewsAdmin />
                        </Tab.Pane>
                        <Tab.Pane eventKey="#link10">
                            <PublicInformationAdmin />
                        </Tab.Pane>
                        <Tab.Pane eventKey="#link11">
                            <DecisionProcessAdmin />
                        </Tab.Pane>
                        <Tab.Pane eventKey="#link12">
                            <UsefulAdmin />
                        </Tab.Pane>
                        <Tab.Pane eventKey="#link13">
                            <ReferencesAdmin />
                        </Tab.Pane>
                        <Tab.Pane eventKey="#link14">
                            <ContactsAdmin />
                        </Tab.Pane>
                        <Tab.Pane eventKey="#link15">
                            <PublicNoticesAdmin />
                        </Tab.Pane>
                        <Tab.Pane eventKey="#link16">
                            <EconomyAdmin />
                        </Tab.Pane>
                        <Tab.Pane eventKey="#link17">
                            <CommunityAdmin />
                        </Tab.Pane>
                        <Tab.Pane eventKey="#link18">
                            <AdministrationAdmin />
                        </Tab.Pane>
                        <Tab.Pane eventKey="#link19">
                            <ExtraordinaryCommissionsAdmin />
                        </Tab.Pane>
                        <Tab.Pane eventKey="#link20">
                            <CitizensHearingProgramAdmin />
                        </Tab.Pane>
                        <Tab.Pane eventKey="#link21">
                            <ProjectsAdmin />
                        </Tab.Pane>
                        {/* <Tab.Pane eventKey="#link11"><ITEM /></Tab.Pane> */}
                    </Tab.Content>
                </Tab.Container>
            </div>
        </div>
    );
});

export default Admin;
