import { useState, useEffect } from "react";

const DisappearingMessage = ({ message, show }) => {
	const [isVisible, setIsVisible] = useState(show);

	useEffect(() => {
		if (show) {
			setIsVisible(true);
			const timeoutId = setTimeout(() => {
				setIsVisible(false);
			}, 2000); // Adjust the duration as needed

			return () => clearTimeout(timeoutId);
		}
	}, [show]);

	return (
		<div
			className='disappearing-message text-white text-center py-2 px-3 rounded'
			style={{
				display: isVisible ? "block" : "none",
				backgroundColor: "green",
				position: "absolute",
				top: "10%",
				right: "1%",
			}}>
			<div className='d-flex align-items-center'>
				<i className='bi bi-check-circle icon-3 pe-3'></i>
				{message}
			</div>
		</div>
	);
};

export default DisappearingMessage;
