import { makeAutoObservable } from "mobx";

export default class InformationProcessStore {
	constructor() {
		this._tenders = [
			// {
			//   id: 1,
			//   section: "Licitaţii şi achiziţii",
			//   date: "18.05.2024 11:34",
			//   title: "Licitație deschisă pentru furnizarea de CRM-system",
			//   active: false,
			//   date: "18.05.2024 11:34",
			//   files: [
			//     {
			//       id: 1,
			//       doc_name: "Test Doc 1",
			//       doc_type: "pdf",
			//     },
			//     {
			//       id: 2,
			//       doc_name: "Test Doc 2",
			//       doc_type: "docx",
			//     },
			//   ],
			// },
			// {
			//   id: 2,
			//   section: "Licitaţii şi achiziţii",
			//   date: "18.05.2024 11:34",
			//   title: "Licitație pentru crearea de spații virtuale de recreere",
			//   active: false,
			//   date: "18.05.2024 11:34",
			//   files: [
			//     {
			//       id: 1,
			//       doc_name: "Test Doc 1",
			//       doc_type: "pdf",
			//     },
			//     {
			//       id: 2,
			//       doc_name: "Test Doc 2",
			//       doc_type: "docx",
			//     },
			//   ],
			// },
			// {
			//   id: 3,
			//   section: "Licitaţii şi achiziţii",
			//   date: "18.05.2024 11:34",
			//   title: "Licitație pentru restaurarea monumentelor istorice",
			//   active: false,
			//   date: "18.05.2024 11:34",
			//   files: [
			//     {
			//       id: 1,
			//       doc_name: "Test Doc 1",
			//       doc_type: "pdf",
			//     },
			//     {
			//       id: 2,
			//       doc_name: "Test Doc 2",
			//       doc_type: "docx",
			//     },
			//   ],
			// },
			// {
			//   id: 4,
			//   section: "Licitaţii şi achiziţii",
			//   date: "18.05.2024 11:34",
			//   title:
			//     "Achiziționarea de materiale pentru construcția de adăposturi pentru animale",
			//   active: false,
			//   date: "18.05.2024 11:34",
			//   files: [
			//     {
			//       id: 1,
			//       doc_name: "Test Doc 1",
			//       doc_type: "pdf",
			//     },
			//     {
			//       id: 2,
			//       doc_name: "Test Doc 2",
			//       doc_type: "docx",
			//     },
			//   ],
			// },
			// {
			//   id: 5,
			//   section: "Licitaţii şi achiziţii",
			//   date: "18.05.2024 11:34",
			//   title: "Achiziționarea de echipamente medicale moderne pentru spitale",
			//   active: false,
			//   date: "18.05.2024 11:34",
			//   files: [
			//     {
			//       id: 1,
			//       doc_name: "Test Doc 1",
			//       doc_type: "pdf",
			//     },
			//     {
			//       id: 2,
			//       doc_name: "Test Doc 2",
			//       doc_type: "docx",
			//     },
			//   ],
			// },
		];
		this._acquisition = [
			// {
			//   id: 1,
			//   section: "Planuri de achiziții",
			//   date: "18.05.2024 11:34",
			//   title: "Plan de achiziții de echipamente medicale pentru 2024",
			//   active: false,
			//   date: "18.05.2024 11:34",
			//   files: [
			//     {
			//       id: 1,
			//       doc_name: "Test Doc 1",
			//       doc_type: "pdf",
			//     },
			//     {
			//       id: 2,
			//       doc_name: "Test Doc 2",
			//       doc_type: "docx",
			//     },
			//   ],
			// },
		];

		this._vacancies = [
			// {
			//   id: 1,
			//   section: "Posturi vacante",
			//   date: "18.05.2024 11:34",
			//   title: "Posturi vacante la consiliul raional ",
			//   active: false,
			//   date: "18.05.2024 11:34",
			//   files: [
			//     {
			//       id: 1,
			//       doc_name: "Test Doc 1",
			//       doc_type: "pdf",
			//     },
			//     {
			//       id: 2,
			//       doc_name: "Test Doc 2",
			//       doc_type: "docx",
			//     },
			//   ],
			// },
		];

		this._consultation = [
			// {
			//   id: 1,
			//   section: "Consultare publică",
			//   date: "18.05.2024 11:34",
			//   title:
			//     "Consultații publice cu privirea la Proiectul  „Eminescu: o cultură – o rută în zona transfrontalieră Botoșani – Bălți”",
			//   article:
			//     "Se anunta organizarea consultatiilor publice cu privinta la proiectul „Eminescu: o cultură – o rută în zona transfrontalieră Botoșani – Bălți” Care se va petrece pe data 20.08.2024 in incinta Primariei municipiului Balti, cabinetul 101.",
			//   document: ["www.facebook.com/"],
			// },
			// {
			//   id: 2,
			//   section: "Consultare publică",
			//   date: "18.05.2024 11:34",
			//   title:
			//     "Inițierea procedurilor de consultări publice cu societatea civilă",
			//   article:
			//     "Se anunta inițierea procedurilor de consultări publice cu societatea civilă în procesul decizional „Cu privire la modificarea Regulamentului privind conferirea titlului „Cetăţean de onoare al municipiului Bălţi”",
			//   document: ["www.facebook.com/"],
			// },
			// {
			//   id: 3,
			//   section: "Consultare publică",
			//   date: "18.05.2024 11:34",
			//   title:
			//     "Initierea procedurii de aprobarea proiectului „Modernizarea infrastructurii spațiilor publice...”",
			//   article:
			//     "Se anunta initierea procedurii de aprobarea proiectului „Modernizarea infrastructurii spațiilor publice și instituțiilor educaționale în zona de revitalizare din municipiul Bălți””",
			//   document: ["www.facebook.com/"],
			// },
		];
		this._initiation = [
			// {
			//   id: 0,
			//   section: "Inițiere proiect",
			//   date: "18.05.2024 11:34",
			//   title:
			//     "Inițierea consultărilor publice la proiectul deciziei Consiliului municipal Bălți",
			//   article:
			//     "Cu privire la inițierea consultărilor publice la proiectul deciziei Consiliului municipal Bălți „Cu privire la aprobarea Strategiei de dezvoltare a Bibliotecii Municipale „Eugeniu Coșeriu” Bălți pentru anii 2023 – 2027”",
			//   document: ["www.facebook.com/"],
			// },
			// {
			//   id: 1,
			//   section: "Inițiere proiect",
			//   date: "18.05.2024 11:34",
			//   title:
			//     "Initierea procedurii de aprobarea proiectului „Modernizarea infrastructurii spațiilor publice...”",
			//   article:
			//     "Se anunta initierea procedurii de aprobarea proiectului „Modernizarea infrastructurii spațiilor publice și instituțiilor educaționale în zona de revitalizare din municipiul Bălți”",
			//   document: ["www.facebook.com/"],
			// },
		];
		this._draftDecisions = [
			// {
			//   id: 1,
			//   title:
			//     "ARegulamentul privind constituirea și funcționarea Consiliului raional Edineț",
			//   active: false,
			//   date: "18.05.2024 11:34",
			//   files: [
			//     {
			//       id: 1,
			//       doc_name: "Test Doc 1",
			//       doc_type: "pdf",
			//     },
			//     {
			//       id: 2,
			//       doc_name: "Test Doc 2",
			//       doc_type: "docx",
			//     },
			//   ],
			// },
			// {
			//   id: 2,
			//   title:
			//     "BRegulile de întocmire şi cerinţele de informare, consultare şi participare în procesul de elaborare şi adoptare a deciziilor în cadrul Consiliului raional Edineț",
			//   active: false,
			//   date: "18.05.2024 11:34",
			//   files: [
			//     {
			//       id: 1,
			//       doc_name: "Test Doc 1",
			//       doc_type: "pdf",
			//     },
			//     {
			//       id: 2,
			//       doc_name: "Test Doc 2",
			//       doc_type: "docx",
			//     },
			//   ],
			// },
		];
		this._recommendations = [
			// {
			//   id: 0,
			//   section: "Sinteza recomandărilor",
			//   date: "18.05.2024 11:34",
			//   title: "Plan de achiziții de echipamente medicale pentru 2024",
			//   article:
			//     "Tabel de divergențe la inițierea elaborării proiectului de decizie CMC „Cu privire la înființarea Întreprinderii Municipale „Supraveghere și protecția animalelor” și modificarea Regulamentelor aprobate prin decizia Consiliului municipal Chișinău 4/4 din 17.09.2019”",
			//   document: ["www.facebook.com/"],
			// },
			// {
			//   id: 1,
			//   section: "Sinteza recomandărilor",
			//   date: "18.05.2024 11:34",
			//   title:
			//     "Sinteza propunerilor acumulate în cadrul consultărilor publice a proiectului deciziei a Consiliului municipal Chişinău",
			//   article:
			//     "Sinteza propunerilor acumulate în cadrul consultărilor publice a proiectului deciziei a Consiliului municipal Chişinău „Cu privire la modificarea „Regulamentului privind întreţinerea animalelor de companie cu şi fără stăpân în municipiul Chişinău” și a „Regulamentului privind capturarea, transportarea, evidenţa şi îngrijirea câinilor şi a pisicilor fără supraveghere şi fără stăpân din municipiul Chişinău”",
			//   document: ["www.facebook.com/"],
			// },
			// {
			//   id: 2,
			//   section: "Sinteza recomandărilor",
			//   date: "18.05.2024 11:34",
			//   title:
			//     "Sinteza propunerilor acumulate în cadrul consultărilor publice a proiectului deciziei a Consiliului municipal Chişinău",
			//   article:
			//     "Sinteza propunerilor acumulate în cadrul dezbaterilor publice a proiectului deciziei Consiliului municipal Chişinău „Cu privire la aprobarea Regulamentului privind modul de instituire, atribuire și condițiile de folosire a locuințelor de manevră în municipiul Chișinău”",
			//   document: ["www.facebook.com/"],
			// },
		];
		this._publicNotices = [];
		makeAutoObservable(this);
	}

	setTenders(tenders) {
		this._tenders = tenders;
	}

	get tenders() {
		return this._tenders;
	}

	setAcquisition(acquisition) {
		this._acquisition = acquisition;
	}

	get acquisition() {
		return this._acquisition;
	}

	setVacancies(vacancies) {
		this._vacancies = vacancies;
	}

	get vacancies() {
		return this._vacancies;
	}

	setConsultation(consultation) {
		this._consultation = consultation;
	}

	get consultation() {
		return this._consultation;
	}

	setInitiation(initiation) {
		this._initiation = initiation;
	}

	get initiation() {
		return this._initiation;
	}

	setRecommendations(recommendations) {
		this._recommendations = recommendations;
	}

	get recommendations() {
		return this._recommendations;
	}

	setDraftDecisions(draftDecisions) {
		this._draftDecisions = draftDecisions;
	}

	get draftDecisions() {
		return this._draftDecisions;
	}

	setPublicNotices(publicNotices) {
		this._publicNotices = publicNotices;
	}

	get publicNotices() {
		return this._publicNotices;
	}
}
